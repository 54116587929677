import { enqueueSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
import axios from "axios";

import { makeFunctionCalledOnce } from "../Helper/data";
import { Constant } from "../Helper";
import i18n from "../i18n";

const BASE_URL = process.env.REACT_APP_API_URL;
const CancelToken = axios.CancelToken;
let MAX_RETRIES = 2;
let MS = 5 * 1000;
export let cancelApiCall;
// Store requests
let sourceRequest = {};
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  // timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const allowTokenUpdate =
  typeof window !== "undefined" &&
  !window?.location?.pathname?.includes("/login") &&
  !window?.location?.pathname?.includes("/register") &&
  !window?.location?.pathname?.includes("/registration/") &&
  !window?.location?.pathname?.includes("/auth/");

axiosInstance.interceptors.request.use(
  async (config) => {
    const Token = localStorage.getItem("token");
    if (Token) {
      config.headers.Authorization = "Bearer " + Token;
      config.cancelToken = new CancelToken(function executor(c) {
        cancelApiCall = c;
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (request) => {
    // If the application exists cancel
    if (sourceRequest[request.url]) {
      sourceRequest[request.url].cancel("Automatic cancellation");
    }

    // Store or update application token
    const axiosSource = axios.CancelToken.source();
    sourceRequest[request.url] = { cancel: axiosSource.cancel };
    request.cancelToken = axiosSource.token;

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      !error.config?.doNotCatchRespond &&
      (error.response?.status === 429 ||
        error.response?.status === 500 ||
        error.response?.status === 503 ||
        error.response?.status === 404 ||
        error.message === "Network Error")
    ) {
      const retryCount = (error.config.retryCount || 0) + 1;
      error.config.retryCount = retryCount;
      if (retryCount <= MAX_RETRIES) {
        return new Promise((resolve, _) => {
          setTimeout(() => {
            resolve(axios(error.config));
          }, retryCount * MS);
        });
      } else {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

const APICall = async (
  method = "post",
  endPoint = "",
  body = undefined,
  conf
) => {
  let config = {
    method: method.toLowerCase(),
  };
  if (endPoint) {
    config.url = endPoint;
  }
  if (body) {
    if (conf?.isFormData) {
      const formData = new FormData();
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          formData.append(key, body[key]);
        }
      }
      config.data = formData;
    } else {
      config.data = body;
    }
  }
  if (conf) {
    config = { ...config, ...conf };
  }

  return new Promise((resolve, reject) => {
    axiosInstance(config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (Constant.isDev) {
          console.log(
            `Dev logs ${error && error?.response ? "resolve" : "reject"}====>>`,
            error && error?.response ? error?.response : error?.message
          );
        }
        if (error && error?.message === "canceled") {
          return;
        }
        if (
          error &&
          error?.response &&
          error?.response?.status === 401 &&
          allowTokenUpdate
        ) {
          updateToken(error);
          return;
        }
        if (!config?.doNotHandleError) {
          if (error && error?.response) {
            resolve(error.response);
          } else {
            reject(error);
          }
        }
        let catchRespond = !config?.doNotCatchRespond;

        if (
          Array.isArray(error?.response?.data)
            ? error?.response?.data?.some((o1) =>
                o1?.category?.[0]?.includes("Cannot set category.")
              )
            : error?.response?.data?.category?.[0]?.includes(
                "Cannot set category."
              )
        ) {
          catchRespond = false;
        }

        if (catchRespond) {
          const user = localStorage.getItem("LoggedInUser");

          Sentry.setExtra("Api Error  ==> ", {
            message: error?.message,
            response: error?.response,
            status: error?.status || error?.response?.status,
            time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            user,
          });
          Sentry.captureException(error);
          let message = i18n?.t(error?.message || "");
          if (error && error?.response) {
            if (error.response?.status === 401) {
              message = "Token expired";
            }
            if (error.response?.status === 400) {
              const hasAnyErrorKey =
                error?.response?.data &&
                Object.keys(error?.response?.data)?.length > 0
                  ? Object.values(error?.response?.data)?.[0]?.[0]
                  : null;
              const key = Object.keys(error?.response?.data)?.[0];
              if (hasAnyErrorKey) {
                message =
                  (key !== "non_field_errors" &&
                  i18n?.t(hasAnyErrorKey) === hasAnyErrorKey
                    ? `${key}: `
                    : ``) + i18n?.t(hasAnyErrorKey);
              }
            }
            if (error.response?.status === 404) {
              message = "Oops.. data not found";
            }
            if (
              error.response?.status === 403 ||
              error.response?.status === 504
            ) {
              message = error.response?.data?.detail;
            }
            if (error.response?.status === 500) {
              message = "Internal server error";
            }
          }
          if (message) {
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 5000,
              preventDuplicate: true,
            });
          }

          throw new Error(error);
        }
      });
  });
};

const updateToken = async (error) => {
  let refresh = localStorage.getItem("refreshToken");
  if (refresh) {
    let obj = {
      refresh: refresh,
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/refresh/`, obj)
      .then((res) => {
        localStorage.setItem("token", res?.data.access);
        localStorage.setItem("refreshToken", refresh);
        Promise.resolve(error.response);
        if (allowTokenUpdate) {
          setTimeout(() => {
            let newInitStandardProviders = makeFunctionCalledOnce(() => {
              window.location.reload();
            })();
          }, 1500);
        } else {
          window.location.href = "/login";
        }
      })
      .catch((error) => {
        if (Constant.isTest) {
          console.log("<== refresh token  error==>", error);
        }
        window.location.href = "/login";
      });
  } else {
    if (Constant.isTest) {
      console.log("<== refresh token not found ==>");
    }
    window.location.href = "/login";
  }
};
export default APICall;
