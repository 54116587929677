import {
  useMediaQuery,
  InputBase,
  useTheme,
  Tooltip,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import {
  convertFormatAmountToNumber,
  formatDateToLocal,
  formatAmount,
} from "../../../Helper/data";
import { addAccount, setAccounts } from "../../../store/slices/global";
import { setTodayLiq } from "../../../store/slices/board";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "./../../../Helper/data";
import { Color } from "../../../Helper";
import APICall from "../../../APICall";

const accountType = initialData?.account_Type
  ?.filter((o1) => !["Loan", "CREDIT_CARD"]?.includes(o1.value))
  ?.map((o1) => o1.value);
const BalanceView = ({ hide }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("isTablet"));
  const { t } = useTranslation();
  const [manualAccount, setManualAccount] = useState(null);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [accountBalanceUpdateDate, setAccountBalanceUpdateDate] =
    useState(false);

  //redux state
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const todayLiq = useSelector((state) => state.boardSlice?.todayLiq);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const length = formatAmount({
    amount: todayLiq,
  })?.length;

  //lifecycle
  useEffect(() => {
    if (datasetAccountList?.length > 0) {
      const accountsList = datasetAccountList?.filter(
        (o1) => o1?.active && o1?.account && !o1.internal_dataset
      );
      let last_sync_dates = accountsList
        ?.filter((o1) => o1?.last_sync_date)
        ?.map((o1) => o1?.last_sync_date);

      if (last_sync_dates?.length > 0) {
        const latestDate = last_sync_dates.reduce((latest, date) => {
          return new Date(date) > new Date(latest) ? date : latest;
        }, last_sync_dates?.[0]);
        setAccountBalanceUpdateDate(latestDate);
      } else {
        setAccountBalanceUpdateDate(null);
      }

      const todayLiq = accountsList?.reduce((allTotal, account) => {
        const sum = account?.account?.balances?.reduce((total, record) => {
          return (
            parseFloat(total) +
            parseFloat(
              accountType?.includes(record?.name) || record?.name === "booked"
                ? Number(record?.amount || 0) +
                    Number(record?.ignored_value || 0)
                : 0
            )
          );
        }, 0);
        return parseFloat(allTotal) + parseFloat(sum ?? 0);
      }, 0);

      dispatch(setTodayLiq(todayLiq?.toFixed(2)));

      if (accountsList?.length === 1 && accountsList?.[0]?.type === 1) {
        setManualAccount(accountsList?.[0]?.account);
      } else {
        setManualAccount(null);
      }
    } else {
      dispatch(setTodayLiq(0));
    }
  }, [datasetAccountList, uuid, refreshData]);

  //api
  const get_starting_date_balance = async (accountID, balanceID, obj) => {
    await APICall(
      "get",
      EndPoints.accounts + `${accountID}/balances/${balanceID}/values/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data?.results[0];
        update_starting_date_balance(accountID, balanceID, data?.uuid, {
          value: obj.amount,
          date: obj?.starting_date,
        });
      }
    });
  };

  const update_starting_date_balance = async (
    accountID,
    balanceID,
    valueID,
    obj
  ) => {
    let data = null;
    await APICall(
      "patch",
      EndPoints.accounts +
        `${accountID}/balances/${balanceID}/values/${valueID}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        getAccountsApi();
      }
    });
    return data;
  };

  const updateBalanceToAccount = async (accountId, balanceId, obj) => {
    await APICall(
      "patch",
      EndPoints.accounts + `${accountId}/balances/${balanceId}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        get_starting_date_balance(accountId, balanceId, obj);
      }
    });
  };

  const addBalanceToAccount = async (data, obj, type = "old") => {
    await APICall(
      "post",
      EndPoints.accounts + `${data.uuid}/balances/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
        if (obj.starting_date) {
          add_starting_date_balance(obj?.account, response.data?.uuid, {
            date: obj.starting_date,
            value: response.data?.amount,
          });
        }
        if (type === "new") {
          data = { ...data, balances: [response.data] };
          setManualAccount({ ...data, balances: [{ ...response.data, obj }] });
          dispatch(addAccount(data));
        }
      }
    });
  };

  const add_starting_date_balance = async (accountID, balanceID, obj) => {
    let data = null;
    await APICall(
      "post",
      EndPoints.accounts + `${accountID}/balances/${balanceID}/values/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
    });
    return data;
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  //function
  const onChange = (e) => {
    let { value } = e.target;
    const amount = convertFormatAmountToNumber({ amount: value });

    setAllowUpdate(true);

    dispatch(setTodayLiq(amount));
  };

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
    if (allowUpdate) {
      if (manualAccount?.balances?.length > 0) {
        updateBalanceToAccount(
          manualAccount?.uuid,
          manualAccount?.balances[0]?.uuid,
          {
            name: manualAccount.type,
            amount: todayLiq?.trim() === "" ? 0 : todayLiq,
            starting_date:
              manualAccount?.balances[0]?.starting_date ??
              format(new Date(), "yyyy-MM-dd"),
          }
        );
      } else {
        let data = {
          account: manualAccount?.uuid,
          currency: manualAccount?.currency,
          name: manualAccount?.type,
          amount: todayLiq?.trim() === "" ? 0 : todayLiq,
          starting_date: format(new Date(), "yyyy-MM-dd") ?? null,
        };
        addBalanceToAccount(manualAccount, data, "new");
      }

      setAllowUpdate(false);
    }
  };

  if (hide || isTablet) {
    return null;
  }

  return (
    <Tooltip
      title={`${
        accountBalanceUpdateDate
          ? `${t("Current Balance, Last update on")} ${formatDateToLocal(
              new Date(accountBalanceUpdateDate),
              "yyyy-MM-dd"
            )}`
          : ""
      }`}
      disableFocusListener
      placement="top"
    >
      <Box
        className={"kanban_10_step"}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          mx: "0.5rem",
        }}
      >
        <InputBase
          disabled={!manualAccount}
          onFocus={onFocus}
          onBlur={onBlur}
          sx={{
            width: `${0.95 * length}rem`,
            minWidth: "4rem",
            maxWidth: "10rem",
            height: "2.5rem",
            display: "inline-flex",
            alignItems: "center",
            fontSize: "1.1rem",
            fontWeight: 600,
            color:
              todayLiq >= 0
                ? theme.palette.color.green[700]
                : theme.palette.color.red[700],
            backgroundColor:
              todayLiq >= 0
                ? theme.palette.color.green[50]
                : theme.palette.color.red[50],

            cursor: "auto",
            borderRadius: theme.borderRadius.main,
            px: "0.5rem",
            border: `1px solid ${
              todayLiq >= 0
                ? theme.palette.color.green[200]
                : theme.palette.color.red[200]
            }`,
            transition: "all 500ms",
            "& input": {
              textAlign: "right",
              py: "0.4rem",
            },
            "&:hover": {
              color:
                todayLiq >= 0
                  ? theme.palette.color.green[800]
                  : theme.palette.color.red[800],
              backgroundColor: Color?.white,
            },
            ".Mui-disabled": {
              WebkitTextFillColor: `${
                todayLiq >= 0
                  ? theme.palette.color.green[500]
                  : theme.palette.color.red[500]
              } !important`,
            },
          }}
          value={formatAmount({
            amount: todayLiq,
          })}
          onChange={onChange}
          placeholder=""
        />
      </Box>
    </Tooltip>
  );
};
export default React.memo(BalanceView);
