import {
  ButtonProps as MuiButtonProps,
  useTheme,
  Tooltip,
  alpha,
} from "@mui/material";
import React, { forwardRef, Ref, useRef } from "react";
import Button from "@mui/lab/LoadingButton";

import KeyboardSortCutKey from "../KeyboardSortCutKey";

// Only include variant, size, and color
type ButtonBaseProps = Pick<MuiButtonProps, "variant" | "size" | "color">;

// Use all except disableRipple
// type ButtonBaseProps = Omit<MuiButtonProps, "disableRipple">;

interface ButtonProps extends ButtonBaseProps {
  label: string;
  onClick?: (e: any) => void;
  tooltipLabel?: string;
  disabled?: boolean;
  width?: string;
  showIcon?: boolean; // Add this line
  loading?: boolean;
  isSecondary?: boolean;
  keyboardShortcuts?: any;
  allowKeysToTrigger?: any;
  hideKey?: boolean;
  icon?: React.ReactNode;
  sx?: any;
  keyWrapperStyle?: any;
  keyStyle?: any;
}
const AddButton = forwardRef(
  (props: ButtonProps, _ref: Ref<HTMLButtonElement> | null | any) => {
    const theme = useTheme();
    const buttonRef = useRef(null);
    const {
      label,
      onClick,
      tooltipLabel = "",
      disabled = false,
      width,
      showIcon = true,
      icon,
      sx,
      loading,
      isSecondary,
      keyboardShortcuts = ["Enter"],
      allowKeysToTrigger,
      hideKey,
      keyWrapperStyle,
      keyStyle,
      ...rest
    } = props;

    return (
      <Tooltip title={tooltipLabel}>
        <Button
          ref={buttonRef}
          onClick={disabled ? undefined : onClick}
          startIcon={showIcon ? (icon ? icon : "+") : undefined}
          sx={{
            textTransform: "initial",
            fontSize: "0.8rem",
            lineHeight: "normal",
            paddingInline: "1rem",
            height: "2.25rem",
            width: width ? width : "auto",
            color: isSecondary
              ? theme.palette.color.slate[600]
              : theme.palette.color.white,
            border: isSecondary
              ? `1px solid ${theme.palette.color.slate[300]}`
              : 0,
            backgroundColor: isSecondary
              ? theme.palette.color.slate[50]
              : theme.palette.primary.main,
            borderRadius: theme.borderRadius.main,
            fontWeight: theme?.typography?.fontWeightBold,
            opacity: disabled ? 0.5 : 1,
            "& .MuiButton-startIcon": {
              fontSize: "1.1rem",
              pb: "1px",
            },
            "&:hover": {
              backgroundColor: isSecondary
                ? theme.palette.color.slate[100]
                : theme.palette.primary.darkHover,
            },
            ...sx,
          }}
          {...rest}
        >
          {label}

          {hideKey ? null : (
            <KeyboardSortCutKey
              keys={keyboardShortcuts}
              buttonRef={buttonRef}
              theme={theme}
              action={onClick}
              allowKeysToTrigger={allowKeysToTrigger}
              style={{
                width: "18px",
                height: "18px",
                marginLeft: "0.5rem",
                backgroundColor: "inherit",
                color: isSecondary
                  ? theme.palette.color.slate[600]
                  : theme.palette.color.white,
                border: `1px solid ${isSecondary ? theme.palette.color.slate[300] : alpha(theme.palette.color.white, 0.3)}`,
                ...keyWrapperStyle,
              }}
              keyStyle={keyStyle}
            />
          )}
        </Button>
      </Tooltip>
    );
  }
);
export default AddButton;
