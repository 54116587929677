import { Box, TextField, Tooltip, useTheme } from "@mui/material";
import { forwardRef, useEffect, useMemo, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";

import { Color, Constant, Fonts } from "../../Helper";
import store from "../../store";

const AmountView = forwardRef(
  (
    {
      width = "100%",
      height = "3.5rem",
      currency,
      locale,
      name = "",
      type = "text",
      fontSize = "0.85rem",
      disabled = false,
      mb = "1rem",
      borderRadius = 1,
      tooltip = "",
      variant = "outlined",
      placeholder = "",
      label = null,
      likeGoogle = false,
      defaultValue = undefined,
      onFocus = () => {},
      onBlur = () => {},
      sx,
      ...rest
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const currencyFormate = store?.getState()?.settingsSlice?.currencyFormate;
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    //state
    const [isFocus, setIsFocus] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [helperText, setHelperText] = useState("");

    let _locale = "de-DE";
    if (currency) {
      _locale = Constant.CurrencyLocale?.[currency];
    } else if (dataSetData?.currency) {
      _locale = Constant.CurrencyLocale?.[dataSetData?.currency];
    }

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    //functions
    const onFocusInput = () => {
      setIsFocus(true);
      global.isInputFocused = true;
      if (onFocus) onFocus();
    };

    const onValueChange = (_value) => {
      setHelperText("");
      setValue(_value);
    };

    const onBlurInput = (e) => {
      setIsFocus(false);
      let { value } = e.target;

      let originalValue = value
        ?.replaceAll(currencyFormate.groupSeparator, "")
        ?.replace(currencyFormate.decimalSeparator, ".")
        ?.trim();
      let formattedValue = e.target.value;
      if (
        originalValue
          .replaceAll("-", "")
          .replaceAll(".", "")
          .replaceAll(",", "").length <= 8
      ) {
        setHelperText("");
        onBlur(originalValue, formattedValue, true);
      } else {
        setHelperText("only up to 8 digit allowed");
      }
      setTimeout(() => {
        global.isInputFocused = false;
      }, 500);
    };

    const CustomInput = useMemo(
      () =>
        forwardRef((props, ref) => {
          return (
            <TextField ref={ref} size="small" variant={variant} {...props} />
          );
        }),
      [variant]
    );

    return (
      <Box
        sx={{
          height,
          mb,
          width,
          "& .MuiFormHelperText-root": {
            marginLeft: "1rem",
            marginTop: 0,
          },
          ".MuiFormControl-root": {
            width: "100%",
          },
          "& input": {
            fontSize: "0.9rem",
          },
          "& .MuiInputLabel-root": {
            color: theme.palette.color.mainTitle,
            fontWeight: theme.typography.fontWeightMediumBold,
            fontSize: fontSize,
            transform: "translate(12px, 13px) scale(1)",
            "&[data-shrink=true]": {
              transform: "translate(12px, 2px) scale(1)",
            },
          },
          ".MuiOutlinedInput-root": {
            width: "100%",
            fontSize: fontSize,
            fontWeight: 600,
            fontFamily: Fonts.Text,
            "& input": {
              height: "1.7em",
            },

            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                backgroundColor: "rgba(0,0,0,0.03)",
              },
            },
          },
          ".MuiOutlinedInput-input": {
            p: "0.5rem",
            backgroundColor: Color.white,
            "&:focus": {
              p: "0.4rem",
              border: `1px solid ${Color.oInputBorderBg}`,
              borderRadius: borderRadius,
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${Color.oInputBorderBg}`,
          },
          ".MuiInputBase-root": {
            borderRadius: borderRadius,
            backgroundColor:
              likeGoogle && isFocus ? Color.inputBG : "transparent",
            "&:before": {
              borderBottom: likeGoogle && "0px !important",
            },
            "&:hover": {
              backgroundColor: likeGoogle && Color.inputBG,
              "&:before": {
                borderBottom: likeGoogle && "0px !important",
              },
            },
            "&:focus": {
              backgroundColor: likeGoogle && Color.inputBG,
            },
            "&.Mui-disabled": {
              backgroundColor: Color.theme.grey[50],
            },
          },
          ...sx,
        }}
      >
        <Tooltip placement="top" title={t(`${tooltip}`)}>
          <CurrencyInput
            ref={_ref}
            id="currency-input"
            type={type}
            name={name}
            disabled={disabled}
            decimalsLimit={2}
            // maxLength={9}
            step={1}
            placeholder={placeholder}
            label={label}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            value={value}
            error={Boolean(helperText)}
            helperText={helperText}
            onValueChange={onValueChange}
            customInput={CustomInput}
            intlConfig={{
              locale: _locale,
            }}
            {...rest}
          />
        </Tooltip>
      </Box>
    );
  }
);
export default AmountView;
