import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";

import AddPresetCard from "../../../../components/TableEditModal/AddPresetCard";

const EmployeePresetView = ({
  disabled,
  width,
  popup_state,
  hideDivider,
  hidePresets = [],
  sx = {},
  cardSx = {},
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const employeeStateTab = useSelector(
    (state) => state.staffSlice?.employeeStateTab
  );

  const _State =
    !employeeStateTab || employeeStateTab === "All"
      ? "Planned"
      : employeeStateTab;
  return (
    <Box
      sx={{
        p: "1.5rem",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "color.slate.50",
        ...sx,
      }}
    >
      {!hidePresets?.includes("EmployeePlanning") ? (
        <AddPresetCard
          disabled={disabled}
          cardSx={cardSx}
          width={width}
          income_expense_type={2}
          preset_type="EmployeePlanning"
          popup_state={popup_state}
          hideEditFormTabBar
          state={_State}
          hideIcon
          highlightIcon={false}
          descriptionCount={162}
        />
      ) : null}
      {hideDivider ? null : (
        <Divider
          textAlign="left"
          sx={{
            width: "100%",
            mt: "1.5rem",
            mb: "0.5rem",
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.color.slate[300],
            fontWeight: theme.typography.fontWeightMediumBold,
            fontSize: "0.8rem",
          }}
        >
          {t("Preset")}
        </Divider>
      )}
      <AddPresetCard
        disabled={disabled}
        cardSx={cardSx}
        width={width}
        income_expense_type={2}
        preset_type="EmployeePlanningA"
        popup_state={popup_state}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
      <AddPresetCard
        disabled={disabled}
        cardSx={cardSx}
        width={width}
        income_expense_type={2}
        preset_type="EmployeePlanningFreelancer"
        popup_state={popup_state}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
      <AddPresetCard
        disabled={disabled}
        cardSx={cardSx}
        width={width}
        income_expense_type={2}
        preset_type="EmployeePlanningWorkingStudent"
        popup_state={popup_state}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
      <AddPresetCard
        disabled={disabled}
        cardSx={cardSx}
        width={width}
        income_expense_type={2}
        preset_type="EmployeePlanningStudentInMarginalEmployment"
        popup_state={popup_state}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />

      <AddPresetCard
        disabled={disabled}
        cardSx={cardSx}
        width={width}
        income_expense_type={2}
        preset_type="EmployeePlanningInternship"
        popup_state={popup_state}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />

      <AddPresetCard
        disabled={disabled}
        cardSx={cardSx}
        width={width}
        income_expense_type={2}
        preset_type="EmployeePlanningMinijob"
        popup_state={popup_state}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
    </Box>
  );
};

export default EmployeePresetView;
