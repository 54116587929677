import {
  CardActionArea,
  Typography,
  useTheme,
  Tooltip,
  Button,
  Avatar,
  Radio,
  Stack,
  Grid,
  Card,
  Box,
} from "@mui/material";
import {
  useImperativeHandle,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { CiBank, CiCirclePlus } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FaExclamation } from "react-icons/fa";
import { enqueueSnackbar } from "notistack";
import { isBefore } from "date-fns";

import {
  setIsAccountSelectOverlayOpen,
  setDataSource,
  setAccounts,
} from "../../../store/slices/global";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import { setStageLoadingText } from "../../../store/slices/appmain";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomModal from "../../../components/Model/CustomModal";
import { isValidTitle, truncate } from "../../../Helper/data";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { setAddFrom } from "../../../store/slices/settings";
import EndPoints from "../../../APICall/EndPoints";
import { queryClient } from "../../../App";
import { Color } from "../../../Helper";
import APICall from "../../../APICall";
import store from "../../../store";

const AccountsSelectOverlay = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const accountRef = useRef(null);

  //redux
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const dataSourceById = useSelector(
    (state) => state?.globalSlice?.dataSourceById
  );
  //state
  const [loader, setLoader] = useState(false);
  const [disableADD, setDisableADD] = useState(true);
  const [banksList, setBanksList] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  const open =
    isAccountSelectOverlayOpen?.open &&
    !isAccountSelectOverlayOpen?.isFinApiWebForm;
  const isBankSelection = isAccountSelectOverlayOpen?.isBankSelection;
  const isBankAccountView = isAccountSelectOverlayOpen?.isBankAccountView;

  //api
  const getAccountList = async (integration_id) => {
    let data = null;
    await APICall(
      "get",
      EndPoints.integrations + integration_id + `/finapi/accounts/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  const getFinApiConnections = async (integration_id) => {
    setIsFetching(true);
    const bankAccountList = await getAccountList(integration_id);
    if (bankAccountList?.length > 0) {
      setIsFetching(false);
      setLoader(false);
      dispatch(
        setAddFrom({
          targetUrl: window.location.pathname,
          integration_id: isAccountSelectOverlayOpen?.payload?.integration_id,
          bankAccountList,
        })
      );
      dispatch(
        setIsAccountSelectOverlayOpen({
          open: true,
          isBankAccountView: true,
          fromBankSelectionView: true,
        })
      );
    } else {
      await APICall(
        "get",
        EndPoints.integrations + integration_id + "/finapi/connections/"
      )
        .then(async (response) => {
          if (response.status === 200 && response.data) {
            const newBank = { uuid: "new_bank", connection_id: true };
            const bankList = [newBank, ...response.data];
            setBanksList(bankList);
            setLoader(false);
            if (
              response.data?.filter((o1) => o1?.connection_id)?.length === 0
            ) {
              setSelectedBank(newBank);
              onAddBank(newBank, [newBank, ...response.data]);
            } else {
              setIsFetching(false);
            }
          }
        })
        .catch(() => {
          setIsFetching(false);
          setLoader(false);
        });
    }
  };

  const makeFinApiConnections = async (integration_id, Banks) => {
    let data = null;
    await APICall(
      "post",
      EndPoints.integrations + integration_id + "/finapi/connections/",
      {}
    ).then((response) => {
      if (response.status === 201 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  const handleSelectBank = (bank) => {
    setDisableADD(false);
    setSelectedBank(bank);
  };

  const onAddBank = async (_bank, _banksList_) => {
    let _selectedBank = _bank || selectedBank;
    let _banksList = _banksList_ || banksList;
    setLoader(true);
    if (_selectedBank?.uuid === "new_bank") {
      _selectedBank = _banksList?.find(
        (o1) =>
          !o1?.connection_id &&
          o1.status === "NOT_YET_OPENED" &&
          isBefore(Date.now(), new Date(o1?.web_form_expiry_date))
      );
      if (!_selectedBank) {
        _selectedBank = await makeFinApiConnections(
          isAccountSelectOverlayOpen?.payload?.integration_id
        );
      }
    }

    dispatch(
      setAddFrom({
        targetUrl: window.location.pathname,
        integration_id: isAccountSelectOverlayOpen?.payload?.integration_id,
        token: _selectedBank?.web_form_id,
        id: _selectedBank?.connection_id ? _selectedBank?.id : null,
      })
    );
    dispatch(
      setIsAccountSelectOverlayOpen({
        open: true,
        fromBankSelectionView: !!_selectedBank?.connection_id,
        isFinApiWebForm: !_selectedBank?.connection_id,
        isBankAccountView: _selectedBank?.connection_id,
        payload: {
          integration_id: isAccountSelectOverlayOpen?.payload?.integration_id,
          token: _selectedBank?.web_form_id,
        },
      })
    );
    setIsFetching(false);
  };

  //function
  const onAdd = async () => {
    if (isBankSelection) {
      onAddBank();
    } else {
      accountRef.current?.onAddAccount();
    }
  };

  const onClose = () => {
    dispatch(setAddFrom(null));
    dispatch(setStageLoadingText(null));
    dispatch(setIsAccountSelectOverlayOpen(null));
  };

  //life cycle method
  useEffect(() => {
    if (open) {
      setLoader(false);
      setDisableADD(true);
    }
  }, [open]);

  useEffect(() => {
    if (
      isBankSelection &&
      !isBankAccountView &&
      isAccountSelectOverlayOpen?.payload?.integration_id
    ) {
      getFinApiConnections(isAccountSelectOverlayOpen?.payload?.integration_id);
    }
  }, [
    isAccountSelectOverlayOpen?.payload?.integration_id,
    isBankAccountView,
    isBankSelection,
  ]);

  if (!open || isFetching) {
    return null;
  }

  return (
    <CustomModal
      modalType="add"
      heading={
        isBankSelection ? t("select_bank_connection") : t("Select_Account")
      }
      open={open}
      backdropClose={false}
      loadingAdd={loader}
      disableADD={disableADD}
      onAdd={onAdd}
      onClose={onClose}
      restProps={{
        slotProps: {
          backdrop: {
            sx: {
              backdropFilter: "blur(6px)",
              WebkitBackdropFilter: "blur(6px)",
            },
          },
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: Color.BodyBG,
          borderBottomLeftRadius: theme.borderRadius.main,
          borderBottomRightRadius: theme.borderRadius.main,
          px: "2rem",
          py: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "50vh",
          width: "60rem",
        }}
      >
        {isBankSelection ? (
          <Grid
            container
            spacing={3}
            sx={{ height: isFetching ? "100%" : "fit-content" }}
          >
            {isFetching ? (
              <ComponentLoader
                loading
                hideNoDataPlaceholder
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              banksList?.map((bank) => {
                if (!bank?.connection_id) return null;
                let status_title = "connection_state_update";
                let status_color = "sky";

                let ds_title = "";
                const isWEB_SCRAPER =
                  bank?.status === "COMPLETED" &&
                  bank?.interfaces?.find(
                    (o1) =>
                      o1.banking_interface === "WEB_SCRAPER" ||
                      o1.banking_interface === "FINTS_SERVER"
                  );
                if (bank?.data_sources?.length > 0) {
                  bank?.data_sources?.forEach((ds, index) => {
                    if (index < 3) {
                      const data_source = dataSourceById?.[ds]?.[0];
                      ds_title =
                        ds_title +
                        (index > 0 ? ` / ` : "") +
                        truncate(data_source?.title, 8);
                    }
                  });
                }

                if (bank?.status === "COMPLETED") {
                  if (isWEB_SCRAPER && bank?.user_action_required) {
                    status_title = "connection_state_connected";
                    status_color = "green";
                  } else if (bank?.user_action_required) {
                    status_title = "connection_state_update";
                    status_title = "connection_state_connected";
                    status_color = "sky";
                  } else {
                    status_title = "connection_state_connected";
                    status_color = "green";
                  }
                }

                if (bank?.status === "UNAUTHORIZED_ACCESS") {
                  status_title = "connection_state_unauthorized_access";
                  status_color = "red";
                }
                if (bank?.status === "DISCONNECTED") {
                  status_title = "connection_state_reconnect";
                  status_color = "red";
                }
                return (
                  <Grid item xs={12} sm={6} md={4} key={bank?.uuid}>
                    <Card
                      onClick={() => handleSelectBank(bank)}
                      sx={{
                        border:
                          selectedBank?.uuid === bank?.uuid
                            ? `2px solid ${theme.palette.primary.main}`
                            : `1px solid ${theme.palette.color.slate[300]}`,
                        boxShadow:
                          selectedBank?.uuid === bank.uuid
                            ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                            : "none",
                        transition: "border-color 0.3s, box-shadow 0.3s",
                        height: "12rem",
                        "&:hover": {
                          "& #connection_remove_icon": {
                            display: "flex",
                          },
                        },
                      }}
                    >
                      <CardActionArea
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: "1.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          position: "relative",
                        }}
                      >
                        {bank?.status && (
                          <Box
                            sx={{
                              position: "absolute",
                              right: "0.25rem",
                              bottom: "0.25rem",
                              width: "fit-content",
                              height: "2rem",
                              px: "0.5rem",
                              py: "0.25rem",
                              borderRadius: theme.borderRadius.main,
                              color: theme.palette.color?.[status_color]?.[500],
                              backgroundColor:
                                theme.palette.color?.[status_color]?.[100],
                              transition: "width 0.3s ease, padding 0.3s ease",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Typography
                              variant="caption"
                              component={"div"}
                              fontWeight={"fontWeightBold"}
                              sx={{
                                lineHeight: "normal",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {`${t(status_title)}`}{" "}
                              {isWEB_SCRAPER ? (
                                <FaExclamation
                                  style={{
                                    fontSize: "0.75rem",
                                    paddingTop: "2px",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <Tooltip
                                placement="top"
                                title={t(
                                  "bank_connections_overlay_connection_count_tooltip",
                                  {
                                    count: bank?.data_sources?.length || 0,
                                  }
                                )}
                              >
                                <Box
                                  component={"span"}
                                  sx={{
                                    borderRadius: theme.borderRadius.main,
                                    color:
                                      theme.palette.color?.[
                                        status_color
                                      ]?.[500],
                                    backgroundColor:
                                      theme.palette.color?.[
                                        status_color
                                      ]?.[200],
                                    fontSize: "0.7rem",
                                    lineHeight: "0.7rem",
                                    minWidth: "1.5rem",
                                    py: "0.35rem",
                                    px: "0.5rem",
                                    ml: "0.5rem",
                                    width: "fit-content",
                                    height: "fit-content",
                                  }}
                                >
                                  {bank?.data_sources?.length || 0}
                                </Box>
                              </Tooltip>
                            </Typography>
                          </Box>
                        )}
                        {bank.status ? (
                          <RemoveIcon
                            bank={bank}
                            payload={isAccountSelectOverlayOpen?.payload}
                            setBanksList={setBanksList}
                            count={bank?.data_sources?.length || 0}
                          />
                        ) : null}
                        <span style={{ height: "calc(100% - 3.5rem)" }}>
                          {bank?.bank ? (
                            bank?.bank?.logo_url ? (
                              <Avatar
                                src={bank?.bank?.logo_url}
                                alt={bank?.bank?.title}
                                sx={{
                                  mb: "1rem",
                                  width: "fit-content",
                                  height: "fit-content",
                                  maxHeight: "100%",
                                  borderRadius: 0,
                                }}
                              />
                            ) : (
                              <CiBank
                                style={{
                                  width: 56,
                                  height: 56,
                                  marginBottom: "1rem",
                                  color:
                                    selectedBank?.uuid === bank?.uuid
                                      ? theme.palette.primary.main
                                      : theme.palette.color.slate[700],
                                }}
                              />
                            )
                          ) : (
                            <CiCirclePlus
                              style={{
                                width: 56,
                                height: 56,
                                color:
                                  selectedBank?.uuid === bank?.uuid
                                    ? theme.palette.primary.main
                                    : theme.palette.color.slate[700],
                                marginBottom: "1rem",
                              }}
                            />
                          )}
                        </span>

                        <Typography
                          variant="body2"
                          fontWeight={"fontWeightMediumBold"}
                          sx={{
                            height: "3.5rem",
                            color:
                              selectedBank?.uuid === bank?.uuid
                                ? theme.palette.primary.main
                                : theme.palette.color.slate[700],
                          }}
                        >
                          {bank?.bank
                            ? `${bank?.bank?.title} ${ds_title ? `(${ds_title})` : ""}`
                            : t("add_new_bank_connection")}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })
            )}
          </Grid>
        ) : isBankAccountView ? (
          <AccountSelectionView
            ref={accountRef}
            setLoader={setLoader}
            setDisableADD={setDisableADD}
          />
        ) : null}
      </Box>
    </CustomModal>
  );
};

export default AccountsSelectOverlay;

const RemoveIcon = ({ bank, payload, setBanksList, count }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const deleteConnections = async () => {
    setLoading(true);
    await APICall(
      "delete",
      EndPoints.integrations +
        `${payload?.integration_id}/finapi/connections/?id=${bank?.id}`
    )
      .then((response) => {
        if (response.status === 204 && response) {
          setBanksList((prev) => prev?.filter((c) => c.id !== bank?.id));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onClick = (e) => {
    e?.stopPropagation();
    if (count === 0) {
      deleteConnections();
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        right: "0.25rem",
        top: "0.25rem",
        width: "2rem",
        height: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height="100%"
          sx={{ width: "100%" }}
        />
      ) : (
        <Tooltip
          placement="top"
          title={t(
            count === 0
              ? "bank_connections_overlay_remove_connection_tooltip"
              : "bank_connections_overlay_remove_connection_not_allowed_tooltip"
          )}
        >
          <Button
            size="small"
            id="connection_remove_icon"
            component="div"
            onClick={onClick}
            sx={{
              zIndex: 1,
              display: "none",
              minWidth: "auto",
              textTransform: "none",
              fontSize: "0.7rem",
              width: "100%",
              height: "100%",
              fontWeight: 500,
              backgroundColor:
                theme.palette.color[count === 0 ? "red" : "slate"][100],
              alignItems: "center",
              justifyContent: "center",
              "& svg": {
                color: theme.palette.color[count === 0 ? "red" : "slate"][400],
                fontSize: "1.3rem !important",
              },
              "&:hover": {
                backgroundColor:
                  theme.palette.color[count === 0 ? "red" : "slate"][100],
                "& svg": {
                  color:
                    theme.palette.color[count === 0 ? "red" : "slate"][600],
                },
              },
            }}
          >
            <MdDeleteOutline />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

const AccountsViewList = ({ item, theme, onClickList, disabled }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClickList}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
        my: "0.5rem",
        p: 0,
        borderRadius: theme.borderRadius.main,
        backgroundColor: item?.isSelected
          ? theme.palette.color.slate[100]
          : null,
        opacity: disabled ? "0.5" : "1",
        "&:hover": {
          backgroundColor: theme.palette.color.slate[50],
        },
      }}
    >
      <Radio
        edge="start"
        checked={item?.isSelected}
        tabIndex={-1}
        disableRipple
        sx={{ pl: "2rem" }}
      />

      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "30%",
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {item?.name}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "20%",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        {item?.type}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "20%",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        {item?.currency}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "30%",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        {item?.iban}
      </Typography>
    </Button>
  );
};

const AccountSelectionView = forwardRef(({ setLoader, setDisableADD }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;
  //redux
  const dataSourceById = useSelector(
    (state) => state.globalSlice?.dataSourceById
  );
  const addFrom = useSelector((state) => state.settingsSlice.addFrom);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const is_recently_tried_import_new_accounts =
    isAccountSelectOverlayOpen?.is_recently_tried_import_new_accounts;

  const isFinbanAccount =
    profile?.email?.includes("@finban.io") ||
    profile?.email?.includes("@e-laborat.de");

  //state
  const [accountList, setAccountList] = useState([]);
  const [dsLoading, setDsLoading] = useState(true);
  const type = "finapi";

  const DS = dataSourceById?.[addFrom?.integration_id]?.[0];
  const isAccountNotFound = useDeferredTimeout({
    value: !dsLoading && accountList?.length === 0,
    timeout: 2000,
  });

  //api
  const onAddAccount = () => {
    const selectedAccount = accountList?.find((o1) => o1.isSelected);
    const obj = {
      id: selectedAccount?.id,
    };
    if (selectedAccount) {
      addAccount(obj, addFrom?.integration_id);
    } else {
      enqueueSnackbar(t("please_select_bank_account"), {
        variant: "info",
        autoHideDuration: 4000,
      });
    }
  };

  const addAccount = async (obj, integration_id) => {
    setLoader(true);
    await APICall(
      "post",
      EndPoints.integrations + integration_id + `/${type}/accounts/`,
      obj,
      { doNotCatchRespond: true }
    ).then(async (response) => {
      if (response.status === 201 && response.data) {
        if (response?.data?.[0]?.uuid) {
          const account = await getAccountByID(response.data?.[0]?.uuid);
          if (account?.bank_details?.title) {
            const dataSource = store?.getState()?.globalSlice?.dataSource;
            const same_name_ds = dataSource?.filter((o1) =>
              o1.title?.includes(account?.bank_details?.title)
            );
            const alreadyAddedTitles = dataSource?.map((o1) => o1.title);
            let count = same_name_ds?.length + 1;
            let title = account?.bank_details?.title + " 1";
            title = isValidTitle({
              alreadyAddedTitles,
              title,
              count,
              node: { title: account?.bank_details?.title },
            });

            await updateDataSourceByID(integration_id, {
              title,
            });
          }
        }
        enqueueSnackbar(t("New_Account_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        setLoader(false);
      }
      dispatch(setAddFrom(null));
      await getAccountsApi();
      await getDataSource();
      const accountByDS = store.getState().globalSlice.accountByDS;
      const account = accountByDS?.[integration_id]?.[0];
      const options = {
        predicate: (query) =>
          query.queryKey[0] === "integrations" &&
          query.queryKey[1]?.bank === account?.bank &&
          query.queryKey[1]?.apiType === "data_source_finapi_connections",
      };
      queryClient.resetQueries(options);
    });
  };

  const getAccountByID = async (id, obj) => {
    let data = null;
    await APICall("get", EndPoints.accounts + `${id}/`).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  const updateDataSourceByID = async (id, obj) => {
    await APICall("patch", EndPoints.integrations + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setDataSource([...response.data.results]));
        dispatch(setIsAccountSelectOverlayOpen(null));
        const options = {
          predicate: (query) => query.queryKey[0] === "transactions",
        };
        queryClient.resetQueries(options);
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const getAccountList = async (integration_id, id) => {
    setDsLoading(true);
    setDisableADD(true);
    if (addFrom?.id) {
      await makeFinApiDataSourceConnection(addFrom?.integration_id, {
        id,
      });
    }
    await APICall(
      "get",
      EndPoints.integrations + integration_id + `/${type}/accounts/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        const data = initSelect(response.data);
        setAccountList(data);
        setDsLoading(false);
      }
    });
  };

  const makeFinApiDataSourceConnection = async (DSID, obj) => {
    await APICall(
      "post",
      EndPoints.integrations + `${DSID}/finapi/selected_bank_connection/`,
      obj,
      { doNotCatchRespond: true }
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
    });
  };

  //life cycle method
  useDebounce(
    () => {
      setLoader(false);
      setDisableADD(true);

      if (addFrom?.bankAccountList?.length > 0) {
        const data = initSelect(addFrom?.bankAccountList);
        setAccountList(data);
        setDsLoading(false);
      } else if (addFrom?.integration_id) {
        // checkFinApiConnection(addFrom?.integration_id);
        getAccountList(addFrom?.integration_id, addFrom?.id);
      }
    },
    300,
    [addFrom?.id, addFrom?.integration_id, addFrom?.bankAccountList],
    true
  );

  //function
  const initSelect = (data, id = null) => {
    return data.map((item) => ({
      ...item,
      isSelected: id ? (id === item.id ? !item?.isSelected : false) : false,
    }));
  };

  const onClickList = (item) => {
    let updatedItemList = accountList.map((element) => {
      if (element.id === item.id) {
        setDisableADD(false);
        return { ...element, isSelected: true };
      } else {
        return { ...element, isSelected: false };
      }
    });
    setAccountList(updatedItemList);
  };

  const getNewAccounts = async () => {
    dsRef?.current?.fetchNewAccounts({ DS });
  };

  useImperativeHandle(ref, () => ({
    onAddAccount,
  }));

  return (
    <div
      ref={ref}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          variant="h6"
          component={"h6"}
          color="color.mainTitle"
          fontWeight={"fontWeightBold"}
          sx={{
            width: "35%",
            alignItems: "center",
            pl: "3.25rem",
          }}
        >
          {t("Name")}
        </Typography>

        <Typography
          variant="h6"
          component={"h6"}
          color="color.mainTitle"
          fontWeight={"fontWeightBold"}
          sx={{
            width: "20%",
            alignItems: "center",
            pl: "0.5rem",
          }}
        >
          {t("Type")}
        </Typography>
        <Typography
          variant="h6"
          component={"h6"}
          color="color.mainTitle"
          fontWeight={"fontWeightBold"}
          sx={{
            width: "20%",
            alignItems: "center",
            pl: "0.75rem",
          }}
        >
          {t("Currency")}
        </Typography>
        <Typography
          variant="h6"
          component={"h6"}
          color="color.mainTitle"
          fontWeight={"fontWeightBold"}
          sx={{
            minWidth: "30%",
            alignItems: "center",
            pl: "1rem",
          }}
        >
          {t("IBAN")}
        </Typography>
      </Box>
      {dsLoading || isAccountNotFound ? (
        <Box
          sx={{
            flex: 1,
            pr: "1.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ComponentLoader
            loading={dsLoading}
            key1="bank_account_list_no_data_text_01"
            key2="bank_account_list_no_data_text_02"
            sx={{
              width: "80%",
            }}
          />
        </Box>
      ) : (
        accountList?.map((item) => {
          return (
            <AccountsViewList
              key={item?.id}
              item={item}
              onClickList={() => onClickList(item)}
              theme={theme}
              disabled={item?.currency !== DS?.currency}
            />
          );
        })
      )}
      {DS?.currency && !dsLoading && !isAccountNotFound ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{
            gap: "0.5rem",
            position: "absolute",
            left: "3.5rem",
            bottom: "1rem",
          }}
        >
          <Tooltip
            followCursor
            title={t("bank_account_list_currency_tooltip")}
            placement="top"
          >
            <Typography
              variant="subtitle2"
              fontWeight={"fontWeightBold"}
              sx={{
                textAlign: "left",
              }}
            >
              {DS?.currency}
            </Typography>
          </Tooltip>

          {isFinbanAccount && beta_enabled ? (
            <TailwindButton
              disable={is_recently_tried_import_new_accounts}
              onClick={getNewAccounts}
              text={t("bank_account_list_get_new_accounts")}
              tooltip={t(
                is_recently_tried_import_new_accounts
                  ? `bank_account_list_get_new_accounts_user_did_recently_tried_tooltip`
                  : "bank_account_list_get_new_accounts_tooltip"
              )}
            />
          ) : null}
        </Stack>
      ) : null}
    </div>
  );
});
