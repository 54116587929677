import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import { Box, Card, Tooltip, Typography, useTheme } from "@mui/material";
import { addMonths, addYears, format, startOfMonth } from "date-fns";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AddchartIcon from "@mui/icons-material/Addchart";
import { useDispatch, useSelector } from "react-redux";
import { PiUsersThreeBold } from "react-icons/pi";
import { TbBuildingBank } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { IoMdPersonAdd } from "react-icons/io";
import { useLocation } from "react-router";
import { startTransition } from "react";
import { v4 as v4uuid } from "uuid";

import { setTransactionsOverlayStatus } from "../../store/slices/datasets";
import { getTailwindColor, truncate } from "../../Helper/data";
import useSubscriptions from "../../hooks/useSubscriptions";
import initialData from "./../../Helper/data";
import { Color } from "../../Helper";
import Icon from "../Icon";

const AddPresetCard = ({
  width = "35rem",
  height = "auto",
  popup_state,
  income_expense_type,
  disabled,
  minCardHeight,
  due_date,
  state,
  hideEditFormTabBar,
  hideIcon,
  descriptionCount = 95,
  categoryId = null,
  preset_type = "Add",
  tab = "planned",
  isCardStyle = true,
  highlightIcon = true,
  cardSx = {},
  ...restProps
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSubscriptionValid] = useSubscriptions();

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );

  const date = due_date ? new Date(due_date) : new Date();
  const isTableView =
    location?.pathname.includes(`/${initialData?.path?.organization}/`) &&
    !location?.pathname.includes("/table");
  let icon = undefined;
  let color = "slate";
  let fontColor = "slate";
  let bg = "slate";
  let type = null;

  if (!isCardStyle) {
    if (income_expense_type === 1) {
      color = "green";
    } else if (income_expense_type === 2) {
      color = "red";
    }
  }

  const iconStyle = {
    fontSize: "1.4rem",
    color: getTailwindColor(
      highlightIcon ? (income_expense_type === 1 ? "green" : "red") : color,
      500
    ),
  };

  if (preset_type === "AddTransaction") {
    icon = <DataSaverOnRoundedIcon sx={iconStyle} />;
    if (income_expense_type === 1) {
      bg = "green";
      fontColor = "green";
    } else if (income_expense_type === 2) {
      bg = "red";
      fontColor = "red";
    } else {
      bg = "slate";
      fontColor = "slate";
    }
  }
  if (preset_type === "RecurringPlanning") {
    icon = <EventRepeatIcon sx={iconStyle} />;
  }
  if (preset_type === "CategoryRecurringPlanning") {
    icon = <LocalOfferOutlinedIcon sx={iconStyle} />;
  }
  if (preset_type === "RevenueRecurringPlanning") {
    icon = <AddchartIcon sx={iconStyle} />;
  }
  if (preset_type === "SubscriptionPlanning") {
    icon = (
      <Icon
        icon={<PiUsersThreeBold />}
        fontSize={"1.3rem"}
        color={iconStyle?.color}
      ></Icon>
    );
  }
  if (preset_type.includes("EmployeePlanning")) {
    type = "employee";
    icon = (
      <Icon
        icon={<IoMdPersonAdd />}
        fontSize={"1.3rem"}
        color={iconStyle?.color}
      ></Icon>
    );
  }
  if (
    preset_type.includes("LoanPlanning") ||
    preset_type.includes("LeasingPlanning")
  ) {
    icon = (
      <Icon
        icon={<TbBuildingBank />}
        fontSize={"1.3rem"}
        color={iconStyle?.color}
      ></Icon>
    );
  }
  if (hideIcon) {
    icon = null;
  }
  const transaction_type = income_expense_type === 1 ? "income" : "expense";
  const heading = income_expense_type
    ? t(`preset_${transaction_type}_${preset_type}_title`)
    : t(`preset_${preset_type}_title`);
  const description = income_expense_type
    ? t(`preset_${transaction_type}_${preset_type}_desc`)
    : t(`preset_${preset_type}_desc`);

  const openEditOverlay = (recurringItem) => {
    if (dataSetData) {
      let item = {
        title: "",
        note: "",
        source: 1,
        invoice_date: null,
        category: categoryId,
        tax: null,
        recurring_rule: null,
        scenario:
          tab === "Booked"
            ? "Base"
            : isTableView
              ? scenarioById?.[highlightedScenarios?.[0]]?.[0]?.title || "Base"
              : "Base",
        dataset: dataSetData?.uuid,
        due_date: format(startOfMonth(date), "yyyy-MM-dd"),
        data_source: dataSetData?.internal_data_source,
        gross_value: "0.00",
        contact: recurringItem?.contact || null,
        income_expense_type,
      };

      if (recurringItem?.recurring_type === "employee") {
        item.title = recurringItem?.name;
      }
      if (["Booked", "Open", "Planned"].includes(tab)) {
        item.state = tab;
      } else {
        item.state = "Planned";
      }

      if (state) {
        item.state = state;
      }

      startTransition(() => {
        dispatch(
          setTransactionsOverlayStatus({
            open: true,
            overlay_type: "drawer_modal_edit_form",
            anchor: "right",
            payload: {
              modalType: "add",
              hideEditFormTabBar,
              item,
              recurringItem,
            },
          })
        );
      });
    }
  };

  const onClick = () => {
    if (!disabled && isSubscriptionValid({ showMessage: true, type })) {
      popup_state?.close();
      if (preset_type === "AddTransaction") {
        openEditOverlay(null);
      }
      if (preset_type === "RecurringPlanning") {
        let recurringItem = {
          name: "",
          recurring_type: "simple",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          tax: null,
          value: "0.00",
        };
        openEditOverlay(recurringItem);
      }
      if (preset_type === "CategoryRecurringPlanning") {
        let recurringItem = {
          name: "",
          recurring_type: "advanced",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          tax: null,
          value: "0.00",
          source: 2,
        };
        openEditOverlay(recurringItem);
      }
      if (preset_type === "RevenueRecurringPlanning") {
        let recurringItem = {
          name: "",
          recurring_type: "advanced",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          tax: null,
          value: "0.00",
          source: 3,
          average: 2,
        };
        openEditOverlay(recurringItem);
      }
      if (preset_type === "SubscriptionPlanning") {
        let recurringItem = {
          name: "",
          recurring_type: "subscription",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          tax: null,
          value: "0.00",
        };
        openEditOverlay(recurringItem);
      }
      if (preset_type === "EmployeePlanning") {
        let recurringItem = {
          name: "",
          value: "",
          employee_role: "",
          salary_type: 2,
          recurring_type: "employee",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          employee_type: "Full-time",
          value_sets: [
            {
              base_value: 0,
              base_value_name: "ongoing_cost",
              title: "ongoing_cost",
              items: [],
            },
            {
              base_value: 0,
              base_value_name: "one_off_cost",
              title: "one_off_cost",
              items: [],
            },
          ],
        };
        openEditOverlay(recurringItem);
      }

      //Employee - Germany 2024
      if (preset_type === "EmployeePlanningA") {
        let recurringItem = {
          name: "Employee - Germany 2024",
          value: "",
          employee_role: "",
          salary_type: 2,
          recurring_type: "employee",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          employee_type: "Full-time",
          value_sets: [
            {
              base_value: 0,
              base_value_name: "ongoing_cost",
              title: "ongoing_cost",
              items: [
                {
                  title: "Krankenversicherung",
                  cost_type: "insurance",
                  type: 2,
                  value: 7.3,
                  repetition: 1,
                  uuid: v4uuid(),
                },
                {
                  title:
                    "Krankenversicherung, kassen-individueller Zusatzbeitrag",
                  cost_type: "insurance",
                  type: 2,
                  value: 0.8,
                  repetition: 1,
                  uuid: v4uuid(),
                },
                {
                  title: "Pflegeversicherung",
                  cost_type: "insurance",
                  type: 2,
                  value: 1.7,
                  repetition: 1,
                  uuid: v4uuid(),
                },
                {
                  title: "Arbeitslosenversicherung",
                  cost_type: "insurance",
                  type: 2,
                  value: 1.3,
                  repetition: 1,
                  uuid: v4uuid(),
                },
                {
                  title: "Rentenversicherung",
                  cost_type: "insurance",
                  type: 2,
                  value: 9.3,
                  repetition: 1,
                  uuid: v4uuid(),
                },

                {
                  title: "Umlage 1 (Lohnfortzahlung im Krankheitsfall)",
                  cost_type: "insurance",
                  type: 2,
                  value: 2,
                  repetition: 1,
                  uuid: v4uuid(),
                },
                {
                  title: "Umlage 2 (Mutterschaft)",
                  cost_type: "insurance",
                  type: 2,
                  value: 0.5,
                  repetition: 1,
                  uuid: v4uuid(),
                },
                {
                  title: "Umlage 3 (Insolvenzgeld)",
                  cost_type: "insurance",
                  type: 2,
                  value: 0.06,
                  repetition: 1,
                  uuid: v4uuid(),
                },
                {
                  title: "Unfallversicherung der Berufsgenossenschaften",
                  cost_type: "insurance",

                  type: 2,
                  value: 1.2,
                  repetition: 1,
                  uuid: v4uuid(),
                },
              ],
            },
            {
              base_value: 0,
              base_value_name: "one_off_cost",
              title: "one_off_cost",
              items: [
                {
                  title: "Weiterbildung",
                  cost_type: "training",
                  type: 1,
                  value: "0",
                  repetition: null,
                  uuid: v4uuid(),
                },
                {
                  title: "Technik",
                  cost_type: "technique",
                  type: 1,
                  value: "0",
                  repetition: null,
                  uuid: v4uuid(),
                },
              ],
            },
          ],
        };

        openEditOverlay(recurringItem);
      }
      //Intern (Germany 2024)
      if (preset_type === "EmployeePlanningInternship") {
        let recurringItem = {
          name: "Intern (Germany 2024)",
          value: "520",
          employee_role: "",
          salary_type: 1,
          recurring_type: "employee",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          employee_type: "Internship",
          value_sets: [
            {
              base_value: 0,
              base_value_name: "ongoing_cost",
              title: "ongoing_cost",
              items: [],
            },
            {
              base_value: 0,
              base_value_name: "one_off_cost",
              title: "one_off_cost",
              items: [],
            },
          ],
        };
        openEditOverlay(recurringItem);
      }
      //Freelancer
      if (preset_type === "EmployeePlanningFreelancer") {
        let recurringItem = {
          name: "",
          value: "",
          employee_role: "",
          salary_type: 2,
          recurring_type: "employee",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          employee_type: "Freelancer",
          value_sets: [
            {
              base_value: 0,
              base_value_name: "ongoing_cost",
              title: "ongoing_cost",
              items: [],
            },
            {
              base_value: 0,
              base_value_name: "one_off_cost",
              title: "one_off_cost",
              items: [
                {
                  title: "Equipment",
                  cost_type: "technique",
                  type: 1,
                  value: "0",
                  repetition: null,
                  uuid: v4uuid(),
                },
              ],
            },
          ],
        };
        openEditOverlay(recurringItem);
      }
      //Working student (Germany 2024)
      if (preset_type === "EmployeePlanningWorkingStudent") {
        let recurringItem = {
          name: "Working student (Germany 2024)",
          value: "",
          employee_role: "",
          salary_type: 2,
          recurring_type: "employee",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          employee_type: "Working student",
          value_sets: [
            {
              base_value: 0,
              base_value_name: "ongoing_cost",
              title: "ongoing_cost",
              items: [
                {
                  title: "Pension insurance",
                  type: 2,
                  value: "9.3",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
              ],
            },
            {
              base_value: 0,
              base_value_name: "one_off_cost",
              title: "one_off_cost",
              items: [],
            },
          ],
        };
        openEditOverlay(recurringItem);
      }
      //Students in marginal employment (2024)
      if (preset_type === "EmployeePlanningStudentInMarginalEmployment") {
        let recurringItem = {
          name: "Students in marginal employment (2024)",
          value: "",
          employee_type: "Student in marginal employment",
          employee_role: "",
          salary_type: 2,
          recurring_type: "employee",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          value_sets: [
            {
              base_value: 0,
              base_value_name: "ongoing_cost",
              title: "ongoing_cost",
              items: [
                {
                  title: "Health insurance",
                  type: 2,
                  value: "13",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
                {
                  title: "Pension insurance ",
                  type: 2,
                  value: "15",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
              ],
            },
            {
              base_value: 0,
              base_value_name: "one_off_cost",
              title: "one_off_cost",
              items: [],
            },
          ],
        };
        openEditOverlay(recurringItem);
      }
      //Minijob (2024)
      if (preset_type === "EmployeePlanningMinijob") {
        let recurringItem = {
          name: "Minijob (2024)",
          value: "",
          employee_type: "Minijob",
          employee_role: "",
          salary_type: 2,
          recurring_type: "employee",
          period: 12,
          repetition: 1,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
          value_sets: [
            {
              base_value: 0,
              base_value_name: "ongoing_cost",
              title: "ongoing_cost",
              items: [
                {
                  title: "Health insurance",
                  type: 2,
                  value: "13",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
                {
                  title: "Pension insurance",
                  type: 2,
                  value: "15",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
                {
                  title: "Pauschalsteuer",
                  type: 2,
                  value: "2",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "Tax",
                },
                {
                  title: "Umlage U1 (Lohnfortzahlung wegen Krankheit)",
                  type: 1,
                  value: "0",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
                {
                  title: "Umlage U2 (Abgaben für Mutterschutz)",
                  type: 1,
                  value: "0",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
                {
                  title: "Umlage U3 (Insolvenzgeldumlage)",
                  type: 1,
                  value: "0",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
                {
                  title: "Unfallversicherung",
                  type: 1,
                  value: "0",
                  repetition: 1,
                  uuid: v4uuid(),
                  cost_type: "insurance",
                },
              ],
            },
            {
              base_value: 0,
              base_value_name: "one_off_cost",
              title: "one_off_cost",
              items: [],
            },
          ],
        };
        openEditOverlay(recurringItem);
      }

      //Loan
      if (preset_type === "SimpleLoanPlanning") {
        let recurringItem = {
          name: "",
          recurring_type: "loan",
          period: -2,
          repetition: 1, //monthly payment
          value: 20000,
          interest_rate: 13,
          interest_rate_type: 3, //yearly interest rate
          payment_default: 12, //months
          loan_calculation_method: 2, //ask for no of months
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addMonths(date, 12), "yyyy-MM-dd"),
        };
        openEditOverlay(recurringItem);
      }
      if (preset_type === "LoanPlanning") {
        let recurringItem = {
          name: "",
          recurring_type: "loan",
          period: -2,
          repetition: 1,
          value: 500000,
          interest_rate: 9,
          payment_default: 22,
          // average_value: 25000,
          loan_calculation_method: 2,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addYears(date, 2), "yyyy-MM-dd"),
        };
        openEditOverlay(recurringItem);
      }
      if (preset_type === "LeasingPlanning") {
        let recurringItem = {
          name: "",
          recurring_type: "leasing",
          period: -2,
          repetition: 1,
          value: 500000,
          interest_rate: 9,
          payment_default: 22,
          // average_value: 25000,
          loan_calculation_method: 2,
          start_date: format(date, "yyyy-MM-dd"),
          end_date: format(addYears(date, 2), "yyyy-MM-dd"),
        };
        openEditOverlay(recurringItem);
      }
    }
  };

  if (isCardStyle) {
    return (
      <CardItem
        width={width}
        height={height}
        icon={icon}
        heading={heading}
        description={description}
        onClick={onClick}
        disabled={disabled}
        minCardHeight={minCardHeight}
        descriptionCount={descriptionCount}
        cardSx={cardSx}
      />
    );
  } else {
    return (
      <Tooltip title={description} placement="top">
        <Box
          {...restProps}
          onClick={onClick}
          disabled={disabled}
          sx={{
            display: "flex",
            padding: "1rem",
            marginBlock: "0.5rem",
            backgroundColor: getTailwindColor(bg, 100),
            borderRadius: 2,
            cursor: disabled ? "not-allowed" : "pointer",
            width: width,
            opacity: disabled ? 0.7 : 1,
            "&:hover": {
              backgroundColor: getTailwindColor(bg, 200),
            },
            ...cardSx,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              width: "100%",
            }}
          >
            {icon}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "1.25rem",
                width: "calc(100% - 3rem)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: getTailwindColor(fontColor, 700),
                }}
              >
                {heading}
              </Typography>

              <Typography
                color="color.description"
                sx={{
                  width: "100%",
                  fontSize: "0.85rem",
                  fontWeight: 500,
                  mt: "0.5rem",
                  color: getTailwindColor(fontColor, 500),
                  height: "2.75rem",
                }}
              >
                {" "}
                {truncate(description, 82)}
              </Typography>
            </div>
          </div>
        </Box>
      </Tooltip>
    );
  }
};

export default AddPresetCard;
const CardItem = ({
  heading,
  description,
  icon,
  disabled,
  minCardHeight,
  descriptionCount,
  width,
  height,
  cardSx,
  ...restProps
}) => {
  const theme = useTheme();
  return (
    <Card
      {...restProps}
      disabled={disabled}
      sx={{
        display: "flex",
        padding: "1.25rem",
        marginBlock: "0.5rem",
        backgroundColor: Color.white,
        borderRadius: theme.borderRadius.main,
        cursor: disabled ? "not-allowed" : "pointer",
        width: width,
        minHeight: minCardHeight,
        height,
        opacity: disabled ? 0.7 : 1,
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        "&:hover": {
          boxShadow: disabled
            ? "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
            : "rgba(0, 0, 0, 0.3) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -1px",
        },
        ...cardSx,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "start",
        }}
      >
        {icon}
        <div
          className="preset_heading_description_container"
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "1.5rem",
          }}
        >
          <Typography
            className="preset_heading"
            color="color.mainTitle"
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
            }}
          >
            {heading}
          </Typography>
          <Tooltip title={description} placement="top">
            <Typography
              className="preset_description"
              color="color.description"
              sx={{
                fontSize: "0.85rem",
                fontWeight: 500,
                mt: "1rem",
              }}
            >
              {truncate(description, descriptionCount)}
            </Typography>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};
