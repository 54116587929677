import {
  ResponsiveContainer,
  ReferenceLine,
  CartesianGrid,
  LabelList,
  BarChart,
  XAxis,
  YAxis,
  Label,
  Cell,
  Bar,
} from "recharts";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { alpha, Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  thinScrollbarStyle,
  formatAmount,
  truncate,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import { Constant } from "../../../../Helper";
import EmptyView from "./Component/EmptyView";

const CashFlowWidget = ({ widgetType, widget }) => {
  const theme = useTheme();
  //state
  const [data, setData] = useState(null);
  // console.log("🚀 ~ CashFlowWidget ~ data:", data);
  const [isLoading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();

  const getEntryProps = (entry) => ({
    isPositive: entry.type === "add" || entry.type !== "less",
    isCashFlowType: entry.type === "cashflow",
    isSeparator: entry.type === "separator",
    isSpace: entry?.type === "space",
  });

  const RenderCustomizedRangeLabel = (props) => {
    const { x, y, width, height, value, id, data } = props;
    const entry = data.find((d) => d.id === id);
    const labelWidth = String(value).length * 6;
    const isLabelOutside = labelWidth > Math.abs(width) - 10;
    const { isPositive, isCashFlowType, isSeparator, isSpace } =
      getEntryProps(entry);

    if (isSeparator || isSpace) return "";
    return (
      <text
        x={
          isCashFlowType
            ? 325
            : isLabelOutside
              ? isPositive
                ? x + width + 10
                : x + width + labelWidth + 10
              : x + width / 2
        }
        y={isCashFlowType ? y + 18 : y + height / 2 + 1}
        fill={
          isCashFlowType
            ? theme.palette.color.black
            : isLabelOutside
              ? theme.palette.color.slate[600]
              : theme.palette.color.white
        }
        fontSize={isCashFlowType ? 14 : 12}
        fontWeight={isCashFlowType ? 600 : "normal"}
        textAnchor={
          isCashFlowType ? "start" : isLabelOutside ? "start" : "middle"
        }
        dominantBaseline="middle"
      >
        {formatAmount({ amount: String(parseFloat(value ?? 0)) })}
      </text>
    );
  };

  const CustomYAxisTick = ({ x, y, payload, data }) => {
    const entry = data.find((d) => d.id === payload.index);
    const isTax = entry.name === "Taxes";
    const { isPositive, isCashFlowType, isSeparator, isSpace } =
      getEntryProps(entry);

    return (
      <g transform={`translate(${x},${y + 2})`}>
        {isSpace ? (
          // Render Space -  Just a blank space
          <foreignObject x={-145} width={300} height={20} y={-10}>
            <div style={{ height: "20px" }}></div> {/* Empty div for space */}
          </foreignObject>
        ) : isSeparator ? (
          // Render Separator as -- Value --
          <foreignObject x={-135} width={300} height={20} y={-10}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                fontSize: "12px",
                color: theme.palette.color.slate[600],
                height: "20px",
                textAlign: "center",
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              {payload.value ? t(payload.value) : ""}
            </div>
          </foreignObject>
        ) : isCashFlowType ? (
          // Render Cash Flow Type with arrow-shaped background
          <svg viewBox="0 0 300 30" x={-145} width={300} height={30} y={-10}>
            <path
              d="M 0 0 L 285 0 L 300 15 L 285 30 L 0 30 Z" // Adjusted path for better fit
              fill={theme.palette.color.slate[100]}
            />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle" // Vertically center text
              textAnchor="middle" // Horizontally center text
              fontSize="14"
              fontWeight="500"
              fill={theme.palette.text.primary}
            >
              {t(payload.value)}
            </text>
          </svg>
        ) : (
          // Default rendering with up/down arrow
          <>
            <foreignObject x={-140} width={30} height={20} y={-4}>
              {isPositive ? (
                <FaArrowUp
                  style={{
                    transform: "rotate(45deg)",
                    color: theme.palette.color.green[500],
                    marginRight: "10px",
                  }}
                />
              ) : (
                <FaArrowUp
                  style={{
                    color: theme.palette.color.red[500],
                    transform: "rotate(135deg)",
                    marginRight: "10px",
                  }}
                />
              )}
            </foreignObject>
            <text
              dx={-115}
              fontSize={14}
              dy={8}
              fill={theme.palette.color.slate[600]}
            >
              {truncate(t(payload.value), 30)}
            </text>
            {isTax && (
              <foreignObject x={-70} width={30} height={20} y={-4}>
                <Tooltip title={t("cashflow_chart_tax_desc")} placement="top">
                  <HelpOutlineOutlinedIcon
                    sx={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      ml: "0.5rem",
                      color: theme.palette.color.slate[600],
                    }}
                  />
                </Tooltip>
              </foreignObject>
            )}
          </>
        )}
      </g>
    );
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    return (
      <text x={x} y={y} fill={theme.palette.color.slate[600]}>
        {formatAmount({
          amount: String(parseFloat(payload.value ?? 0)),
        })}
      </text>
    );
  };

  const renderXAxisLabels = (x, label, color) => {
    return (
      <g>
        <foreignObject x={x + 30} y={15} width={18} height={18}>
          <div
            style={{ width: "20px", height: "20px", backgroundColor: color }}
          />
        </foreignObject>
        <text
          x={x + 50}
          dy="0.355em"
          y={25}
          fontSize={14}
          fill={theme.palette.color.slate[600]}
        >
          {label}
        </text>
      </g>
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        // ...thinScrollbarStyle,
      }}
    >
      <LogicFunctions
        widget={widget}
        setData={setData}
        setIsFetching={setIsFetching}
        setLoading={setLoading}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data && data?.transformedData ? (
        <>
          <Box
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 8,
              paddingRight: 8,
              backgroundColor: alpha(theme.palette.color.slate[50], 0.5),
              position: "sticky",
              top: 0,
              zIndex: 9999, // Ensures it stays above other elements
            }}
          >
            <Grid container spacing={5} justifyContent="center">
              {Object.entries(data.cashFlowData).map(
                ([label, value], index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom={1}
                      borderColor={theme.palette.color.grey[300]}
                      pb={1}
                    >
                      <Typography
                        variant="body1"
                        color={theme.palette.color.grey[600]}
                        fontSize={12}
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        {t(label)}
                      </Typography>
                      <Typography variant="h5" fontWeight="semi-bold">
                        {formatAmount({
                          amount: value,
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              ...thinScrollbarStyle,
            }}
          >
            <ResponsiveContainer width="100%" height={1100}>
              <BarChart
                data={data?.transformedData}
                layout="vertical"
                margin={{ right: 70, top: 10 }}
              >
                {/* Header Background */}
                <svg>
                  <rect
                    fill={alpha(theme.palette.color.slate[50], 0.5)}
                    height="100%"
                    width="100%"
                    y="-1060"
                    x="0"
                  />
                </svg>

                {/* Custom background on left side (Y axis) */}
                <svg>
                  <rect
                    fill={alpha(theme.palette.color.slate[50], 0.5)}
                    height="65.5%"
                    width="320px"
                    y="40"
                    x="0"
                  ></rect>
                  <rect
                    fill={alpha(theme.palette.color.slate[50], 0.5)}
                    height="10.5%"
                    width="320px"
                    y="777"
                    x="0"
                  ></rect>
                  <rect
                    fill={alpha(theme.palette.color.slate[50], 0.5)}
                    height="23%"
                    width="320px"
                    y="910"
                    x="0"
                  ></rect>
                </svg>

                <XAxis
                  type="number"
                  orientation="top"
                  stroke={theme.palette.color.slate[300]}
                  tick={<CustomXAxisTick />}
                >
                  <Label
                    content={renderXAxisLabels(
                      0,
                      truncate(t("cash_flow_widget_cash_received")),
                      theme.palette.color.green[600]
                    )}
                  />
                  <Label
                    content={renderXAxisLabels(
                      150,
                      truncate(t("cash_flow_widget_cash_spent")),
                      theme.palette.color.red[500]
                    )}
                  />
                </XAxis>
                <YAxis
                  dataKey="name"
                  type="category"
                  tickLine={false}
                  width={320}
                  tickMargin={150}
                  tick={<CustomYAxisTick data={data?.transformedData} />}
                  stroke={theme.palette.color.slate[300]}
                />
                <CartesianGrid
                  strokeDasharray="7 7"
                  strokeOpacity={0.5}
                  horizontal={false}
                />

                <Bar dataKey="start" stackId="a" fill="transparent" />
                <Bar dataKey="range" stackId="a">
                  <LabelList
                    dataKey="range"
                    content={
                      <RenderCustomizedRangeLabel
                        data={data?.transformedData}
                      />
                    }
                  />

                  {data?.transformedData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      height={25}
                      fill={
                        entry.type === "add"
                          ? theme.palette.color.green[600]
                          : entry.type === "less"
                            ? theme.palette.color.red[500]
                            : "transparent"
                      }
                    />
                  ))}
                </Bar>
                {data?.transformedData?.map((item, index) => {
                  if (index < data.transformedData.length - 1) {
                    if (
                      ["cashflow", "separator", "space"].includes(item.type)
                    ) {
                      return null;
                    }
                    // Initialize prevY and nextY
                    let prevY = item.id;
                    let nextY = 0;

                    // Find nextY based on the next add or less type object
                    for (
                      let i = index + 1;
                      i < data?.transformedData.length;
                      i++
                    ) {
                      if (
                        data?.transformedData[i].type === "add" ||
                        data?.transformedData[i].type === "less"
                      ) {
                        nextY = data?.transformedData[i].id;
                        break;
                      }
                    }

                    if (item.name === "Financing: Equity") {
                      nextY = 38;
                    }

                    // Render the ReferenceLine component
                    return (
                      <ReferenceLine
                        key={`ref-line-${index}`}
                        strokeDasharray="2 3"
                        stroke={theme.palette.color.slate[400]}
                        strokeWidth={1}
                        segment={[
                          {
                            x: item.end,
                            y: prevY,
                          },
                          {
                            x: item.end,
                            y: nextY,
                          },
                        ]}
                      />
                    );
                  }
                  return null;
                })}
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </Box>
  );
};

export default CashFlowWidget;

const LogicFunctions = ({ setData, setIsFetching, setLoading, widget }) => {
  //redux
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataset
    );
  }, [_selectionCategories, dataset, use_global_categories]);

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        widget_uuid: widget?.uuid,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiStatesIds: widget?.states,
        multiCategoryIds: widget?.categories,
        multiScenarioIds: widget?.scenarios,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: false,
    priority: 3,
  });

  //lifeCycle method
  useDebounce(
    () => {
      WidgetData.refetch();
    },
    500,
    [
      widget?.states,
      widget?.scenarios,
      widget?.categories,
      widget?.start_date,
      widget?.end_date,
    ],
    true
  );

  useEffect(() => {
    setIsFetching(WidgetData?.isFetching);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WidgetData?.isFetching]);

  useEffect(() => {
    if (!WidgetData?.isFetching && WidgetData?.data) {
      generateBalanceSheet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WidgetData?.data, WidgetData?.isFetching]);

  const generateBalanceSheet = () => {
    setLoading(true);
    const bookedData = WidgetData?.data?.results || [];
    // console.log("🚀 ~ generateBalanceSheet ~ bookedData:", bookedData);

    let onlyBookedData = bookedData?.filter((o1) => o1.state === "Booked");

    if (bookedData?.length > 0) {
      let taxExpenseCategories = selectionCategories?.filter(
        (o1) => o1.type_of_cost === "tax"
      );
      let taxExpenseCategoryIds = taxExpenseCategories?.map((o1) => o1.value);

      let services = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "service"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );
      let products = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "Product"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let revenueStream = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "revenue stream"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let cogs = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "cost of goods sold (cogs)"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let researchAndDevelopment = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "research & development"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let salesAndMarketing = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "sales & marketing"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let generalAndAdministrative = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "general & administrative"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let otherOpex = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "other opex"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      const allOpenStates = Constant.openPositionsStates.filter(
        (o1) => !Constant.calculationExcludeStates2.includes(o1)
      );

      let amountReceivable = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            allOpenStates?.includes(item?.state) ? item?.inflow ?? 0 : 0
          ),
        0
      );

      let dealPotentialStateData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item?.state === "Lead - potential" ? item?.inflow ?? 0 : 0
          ),
        0
      );

      let dealWonStateData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(item?.state === "Lead - won" ? item?.inflow ?? 0 : 0),
        0
      );

      let offerDraftAndSentStateData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            ["Offer draft", "Offer sent"].includes(item?.state)
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let offerAcceptAndPartiallyStateData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            ["Offer accepted", "Offer partially calculated"].includes(
              item?.state
            )
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let invoiceStateData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            [
              "Invoice draft",
              "Invoice sent",
              "Invoice open",
              "Partially paid",
              "Sales invoice",
            ].includes(item?.state)
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let purchaseInvoice = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item?.state === "Purchase invoice" ? item?.outflow ?? 0 : 0
          ),
        0
      );

      let depreciationAmortization = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "depreciation & amortization"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let interest = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "interest"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let financingDept = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "financing: dept"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let financingEquity = onlyBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "financing: equity"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let Input_tax_paid = onlyBookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.vat_outflow ?? 0),
        0
      );

      let gropedMonthlyDataByCategory = _.groupBy(bookedData, ({ category }) =>
        category ? category : "uncategorized"
      );

      let categoriesDataInflow = [
        {
          value: "uncategorized",
          label: "Uncategorized Inflow",
          type: 1,
        },
        ...selectionCategories?.filter((o1) => o1.type === 1),
      ];

      let categoriesDataOutflow = [
        {
          value: "uncategorized",
          label: "Uncategorized Outflow",
          type: 2,
        },
        ...selectionCategories?.filter((o1) => o1.type === 2),
      ];

      let unCategorizedIncome = 0;
      categoriesDataInflow?.forEach((o1) => {
        let data = gropedMonthlyDataByCategory[o1.value];
        if (data?.length && !taxExpenseCategoryIds?.includes(o1?.value)) {
          if (o1.value === "uncategorized") {
            let check = data?.find((item) => item.inflow_count > 0);
            if (check) {
              unCategorizedIncome = data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.inflow ?? 0),
                0
              );
            }
          }
        }
      });
      let unCategorizedExpenses = 0;
      categoriesDataOutflow?.forEach((o1) => {
        let data = gropedMonthlyDataByCategory[o1.value];
        if (data?.length && !taxExpenseCategoryIds?.includes(o1?.value)) {
          if (o1.value === "uncategorized") {
            let check = data?.find((item) => item.outflow_count > 0);
            if (check) {
              unCategorizedExpenses = data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.outflow ?? 0),
                0
              );
            }
          }
        }
      });

      const operatingCashFlow =
        unCategorizedIncome +
        services +
        products +
        revenueStream +
        cogs +
        unCategorizedExpenses +
        researchAndDevelopment +
        salesAndMarketing +
        generalAndAdministrative +
        otherOpex +
        Input_tax_paid +
        dealPotentialStateData +
        dealWonStateData +
        offerDraftAndSentStateData +
        offerAcceptAndPartiallyStateData +
        invoiceStateData +
        purchaseInvoice;

      const freeCashFlow = operatingCashFlow + depreciationAmortization;

      const netCashFlow =
        freeCashFlow + interest + financingDept + financingEquity;

      const rawData = [
        //Space
        { name: "", value: 0, type: "space" },
        //--- Separator income --
        {
          name: "cash_flow_widget_income",
          value: services + products + revenueStream,
          type: "separator",
        },

        { name: "cash_flow_widget_service", value: services, type: "add" },
        { name: "cash_flow_widget_product", value: products, type: "add" },
        {
          name: "cash_flow_widget_revenue_stream",
          value: revenueStream,
          type: "add",
        },
        {
          name: "cash_flow_widget_other_income",
          value: unCategorizedIncome,
          type: "add",
        },

        //Space
        { name: "", value: 0, type: "space" },
        //--- Separator expenses --
        { name: "cash_flow_widget_expenses", value: 0, type: "separator" },
        {
          name: "cash_flow_widget_cost_of_goods_sold",
          value: cogs,
          type: "less",
        },
        {
          name: "cash_flow_widget_expenses",
          value: unCategorizedExpenses,
          type: "less",
        },
        {
          name: "cash_flow_widget_research_and_development",
          value: researchAndDevelopment,
          type: "less",
        },
        {
          name: "cash_flow_widget_sales_and_marketing",
          value: salesAndMarketing,
          type: "less",
        },
        {
          name: "cash_flow_widget_general_and_administrative",
          value: generalAndAdministrative,
          type: "less",
        },
        {
          name: "cash_flow_widget_other_opex",
          value: otherOpex,
          type: "less",
        },
        //Space
        { name: "", value: 0, type: "space" },
        {
          name: "cash_flow_widget_taxes",
          value: Input_tax_paid,
          type: "less",
        },

        //Space
        { name: "", value: 0, type: "space" },
        // Separator ADD accounts receivable
        {
          name: "cash_flow_widget_amount_receivables",
          value: amountReceivable,
          type: "separator",
        },
        {
          name: "cash_flow_widget_lead_potential",
          value: dealPotentialStateData,
          type: "add",
        },
        {
          name: "cash_flow_widget_lead_won",
          value: dealWonStateData,
          type: "add",
        },
        {
          name: "offers_draft_and_sent",
          value: offerDraftAndSentStateData,
          type: "add",
        },
        {
          name: "offers_accepted_and_partially",
          value: offerAcceptAndPartiallyStateData,
          type: "add",
        },

        {
          name: "cash_flow_widget_invoice",
          value: invoiceStateData,
          type: "add",
        },

        //Space
        { name: "", value: 0, type: "space" },
        {
          name: "cash_flow_widget_purchase_invoice",
          value: purchaseInvoice,
          type: "less",
        },

        //Space
        //Operating cashflow start
        { name: "", value: 0, type: "space" },
        {
          name: "cash_flow_widget_operating_cash_flow",
          value: operatingCashFlow,
          type: "cashflow",
        },
        //Operating cashflow end

        //Space
        { name: "", value: 0, type: "space" },
        //Space
        { name: "", value: 0, type: "space" },
        //------- free cash flow start -----
        {
          name: "cash_flow_widget_depreciation_and_amortization",
          value: depreciationAmortization,
          type: "less",
        },
        //Space
        { name: "", value: 0, type: "space" },
        {
          name: "cash_flow_widget_free_cash_flow",
          value: freeCashFlow,
          type: "cashflow",
        },
        //------- free cash flow end -----

        //Space
        { name: "", value: 0, type: "space" },
        //Space
        { name: "", value: 0, type: "space" },
        //------------ net cash flow start --------------
        {
          name: "cash_flow_widget_interest",
          value: interest,
          type: "less",
        },
        //Space
        { name: "", value: 0, type: "space" },

        {
          name: "cash_flow_widget_financing_dept",
          value: financingDept,
          type: "add",
        },
        {
          name: "cash_flow_widget_financing_equity",
          value: financingEquity,
          type: "add",
        },
        //Space
        { name: "", value: 0, type: "space" },
        {
          name: "cash_flow_widget_net_cash_flow",
          value: netCashFlow,
          type: "cashflow",
        },
        //Space
        { name: "", value: 0, type: "space" },
        // Net cash flow end
      ];

      const transformedData = rawData.reduce(
        ({ cumulativeValue, result }, item, index) => {
          const start =
            item.type === "separator" || item.type === "space"
              ? 0
              : cumulativeValue;
          const end =
            item.type === "separator" ||
            item.type === "cashflow" ||
            item.type === "space"
              ? item.value
              : cumulativeValue + item.value;
          const range =
            item.type === "separator" || item.type === "space"
              ? 0
              : item.type === "cashflow"
                ? item.value
                : Math.floor(end - start);

          result.push({
            id: index,
            name: item.name,
            start,
            end,
            range,
            type: item.type,
          });

          return {
            cumulativeValue:
              item.type === "separator" ||
              item.type === "cashflow" ||
              item.type === "space"
                ? cumulativeValue
                : end,
            result,
          };
        },
        { cumulativeValue: 0, result: [] }
      ).result;

      // Usage
      setData((prev) => ({
        ...(prev || {}),
        transformedData,
        cashFlowData: {
          cash_flow_widget_operating_cash_flow: operatingCashFlow,
          cash_flow_widget_free_cash_flow: freeCashFlow,
          cash_flow_widget_net_cash_flow: netCashFlow,
        },
      }));
    } else {
      setData(null);
    }
    setLoading(false);
  };

  return <></>;
};
