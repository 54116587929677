import {
  AccordionDetails,
  AccordionSummary,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion,
  Checkbox,
  ListItem,
  useTheme,
  Tooltip,
  Popover,
  Divider,
  Button,
  Alert,
  alpha,
  Stack,
  List,
  Grid,
  Box,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useContext, useEffect, useState, useMemo, useRef, memo } from "react";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";
import { PiMagicWandFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import styled from "@emotion/styled";
import { v4 as v4uuid } from "uuid";
import { format } from "date-fns";

import {
  updateBatchTransactions,
  deleteBatchTransactions,
  cloneBatchTransactions,
  buildUrlFromParams,
  getTransactionById,
  getTailwindColor,
  formatAmount,
} from "../../../Helper/data";
import {
  setTransactionsOverlayStatus,
  setPopupStatus3,
  setPopupStatus4,
  setPopupStatus5,
} from "../../../store/slices/datasets";
import {
  setStageLoadingText,
  setRefreshData,
} from "../../../store/slices/appmain";
import {
  setBankCategories,
  setCategories,
} from "../../../store/slices/category";
import NoDataInfoView from "../../../components/TableEditModal/NoDataInfoView";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../components/ComponentLoader";
import RenderIfVisible from "../../../hooks/HOC/RenderIfVisible";
import useSubscriptions from "../../../hooks/useSubscriptions";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { updateFilters } from "../../../store/slices/global";
import ActionButton from "../../../components/ActionButton";
import CategoryChip from "../../../components/CategoryChip";
import { Color, Constant, Fonts } from "../../../Helper";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "./../../../Helper/data";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import store from "../../../store";

const height = "calc(3rem + 2px)";
// const height = "40px";
const MuiAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 0,
  boxShadow: "none !important",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const MuiAccordionSummary = styled(({ hideIcon, zIndex, ...props }) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.3rem" }} />}
    {...props}
  />
))(({ hideIcon, zIndex }) => {
  return {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper": {
      transform: "rotate(0deg)",
      position: "absolute",
      left: "4rem",
      // top: "29%",
      zIndex: zIndex,
      display: hideIcon ? "none" : "flex",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginBlock: "0.25rem !important",
    },
  };
});

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: 0,
}));

const actionListOption = initialData?.actionListOption;
const borderRadius = "50px";

const BulkListView = ({ width, tab }) => {
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  let updateType = useRef("single");
  let itemRow = useRef({});
  let savedData = useRef({});
  let next = useRef(null);
  let preventClick = useRef(false);
  const page = useRef(1);
  const categoryRef = globalContext?.categoryRef;
  const rulesRef = globalContext?.rulesRef;

  const filter_list = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.list?.[tab] || {}
  );
  const {
    searchText = "",
    start_date = "",
    end_date = "",
    toggle = ["diffValue"],
    typeName = ["Expense", "Income"],
  } = filter_list;

  const refreshData = useSelector((state) => state.appSlice?.refreshData);

  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);

  const isFinbanAccount =
    profile?.email?.includes("@finban.io") ||
    profile?.email?.includes("@e-laborat.de");

  //state
  const [expanded, setExpanded] = useState("");
  const [rows, setRows] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const checkList = useMemo(() => {
    return selectedRowData.map((item) => item?.uuid);
  }, [selectedRowData]);

  //api
  const updateCardsBatch = async (array) => {
    dispatch(setStageLoadingText("process_transactions_is_being_updated"));
    const response = await updateBatchTransactions(array);
    const rulesError = response?.find((o1) =>
      o1?.category?.[0]?.includes("Cannot set category.")
    );
    if (rulesError) {
      rulesRef.current?.onOpen({
        payload: array,
        response,
        message: rulesError?.category?.[0],
        rule_type: rulesError?.type?.[0],
        transaction_rule: rulesError?.transaction_rule?.[0],
        title: itemRow.current?.key?.title,
        transaction_type:
          itemRow.current?.key?.transaction_type === "inflow" ? 1 : 2,
        category: array?.[0]?.category,
      });
    } else {
      resetKanban();
    }
    dispatch(setStageLoadingText(null));
  };

  const fetchBulkList = async () => {
    let buildListParams = null;

    if (!next.current) {
      buildListParams = {
        categorized: false,
        dataset: dataSetData?.uuid,
        transaction_type: "same",
        is_reconciled: false,
        is_split: false,
        title_contains: searchText,
        from_date: start_date,
        to_date: end_date,
        title: toggle?.includes("similarTitle") ? "similar" : "same",
        gross_value: toggle?.includes("diffValue") ? "different" : "same",
      };
      // if (!dataSetData?.use_global_categories) {
      //   buildListParams.category_dataset = dataSetData?.uuid;
      // }else {
      //   buildListParams.global_category = true;
      // }
      if (typeName?.includes("Income") && !typeName?.includes("Expense")) {
        buildListParams = {
          ...buildListParams,
          income_expense_type: 1,
        };
      }
      if (typeName?.includes("Expense") && !typeName?.includes("Income")) {
        buildListParams = {
          ...buildListParams,
          income_expense_type: 2,
        };
      }
    }
    let end_url = "";

    let result = null;
    await APICall(
      "get",
      next.current
        ? next.current?.replace("/api/", "")
        : EndPoints.transactionBulkList +
            buildUrlFromParams(buildListParams) +
            end_url,
      undefined,
      { doNotHandleError: true }
    ).then((response) => {
      if (response.status === 200 && response.data) {
        result = {
          ...response.data,
          results: response.data?.results?.map((o1) => {
            return {
              ...o1,
              uuid: v4uuid(),
            };
          }),
        };
      }
    });
    return result;
  };

  const getBankCategories = async () => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.bank_category).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(
          setBankCategories({ data: response.data.results, type: "all" })
        );
      }
    });
  };

  const getCategories = async () => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.category).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setCategories({ data: response.data.results, type: "all" }));
      }
    });
  };

  const addCategoryApi = async (obj) => {
    await APICall("post", EndPoints.category, obj).then(async (response) => {
      if (response.status === 201 && response.data) {
        let array = [];
        itemRow.current?.items?.forEach((element) => {
          array.push({
            uuid: element?.uuid,
            category: response.data?.uuid,
          });
        });
        await getCategories();
        await updateCardsBatch(array);
      }
    });
  };

  const BulkList = useQuery({
    queryKey: [
      "List",
      {
        dataset: dataSetData?.uuid,
        searchText: searchText,
        start_date: start_date,
        end_date: end_date,
        toggle: toggle || [],
        typeName: typeName || [],
      },
    ],

    queryFn: () => {
      const response = fetchBulkList();
      if (response) {
        return response;
      }
    },
    priority: 3,
    retry: false,
    refetchOnMount: true,
    backgroundFetch: true,
    enabled: false,
    keepPreviousData: true,
  });

  useDebounce(
    () => {
      if (isAllHeaderApiFetched) {
        savedData.current = {};
        next.current = null;
        page.current = 1;
        BulkList.refetch();
      }
    },
    1000,
    [filter_list, refreshData, dataSetData?.uuid],
    true
  );

  useEffect(() => {
    if (isFinbanAccount) {
      getBankCategories();
    }
  }, [isFinbanAccount]);

  useEffect(() => {
    if (BulkList?.data && BulkList?.isSuccess) {
      savedData.current[page.current] = BulkList?.data || [];
      next.current = BulkList?.data?.next;
      setRows(BulkList?.data || []);
      setSelectedRowData([]);
    }
  }, [BulkList?.data]);

  useDebounce(
    () => {
      if (selectedRowData?.length > 0) {
        dispatch(
          setPopupStatus5({
            overlay_type: "bulk",
            payload: { selectedRowData: selectedRowData, resetKanban },
          })
        );
      } else {
        dispatch(setPopupStatus5(null));
      }
    },
    500,
    [selectedRowData],
    true
  );

  //functions
  const resetBulkStates = () => {
    dispatch(setRefreshData(Date.now()));
  };

  const resetKanban = async () => {
    dispatch(setRefreshData(Date.now()));
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) => query.queryKey[0] === "transactions",
    };
    queryClient.resetQueries(options);
  };

  const onClickAssign = (e, item) => {
    e.stopPropagation();
    if (selectedRowData?.length === 0) {
      itemRow.current = item;
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: e.currentTarget,
          overlay_type: "category",
          payload: {
            hideUncategorize: true,
            type: itemRow.current?.key?.transaction_type === "inflow" ? 1 : 2,
            onClickCategoryTitle,
          },
        })
      );
    }
  };

  const onClickAssignSingle = (e, item) => {
    e.stopPropagation();
    if (selectedRowData?.length === 0) {
      itemRow.current = item;
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: e.currentTarget,
          overlay_type: "category",
          payload: {
            hideUncategorize: true,
            type: itemRow.current?.key?.transaction_type === "inflow" ? 1 : 2,
            onClickCategoryTitle,
          },
        })
      );
    }
  };

  const onClickAssignSuggestionCategory = async (e, item) => {
    e.stopPropagation();
    itemRow.current = item;
    const selectionCategoriesByTitle =
      store.getState().categorySlice?.selectionCategoriesByTitle;
    const selectionBankCategoriesByID =
      store.getState().categorySlice?.selectionBankCategoriesByID;
    const bank_category =
      selectionBankCategoriesByID?.[item?.items?.[0]?.bank_category]?.[0];

    const isCategoryFound =
      selectionCategoriesByTitle?.[bank_category?.title]?.[0];
    if (isCategoryFound) {
      let array = [];
      itemRow.current?.items?.forEach((element) => {
        array.push({
          uuid: element?.uuid,
          category: isCategoryFound?.uuid,
        });
      });
      updateCardsBatch(array);
    } else {
      dispatch(setStageLoadingText("process_category_is_being_created"));
      await addCategoryApi({
        parent: null,
        title: bank_category?.title,
        payment_term_value: bank_category?.payment_term_value,
        type: itemRow.current?.key?.transaction_type === "inflow" ? 1 : 2,
      });
    }
  };

  const onClickCategoryTitle = (e, item) => {
    let array = [];

    itemRow.current?.items?.forEach((element) => {
      const category =
        item?.uuid === `unCategorized_category` ? null : item?.uuid;

      array.push({
        uuid: element?.uuid,
        category,
      });
    });
    updateCardsBatch(array);
    closeCategoryOverlay();
  };

  const closeCategoryOverlay = () => {
    dispatch(setPopupStatus4(null));
  };

  const onSelectionModelChange = (e, item) => {
    preventClick.current = true;
    e.stopPropagation();
    let updateData = [];
    const isFound = selectedRowData?.find(
      (element) => element?.uuid === item?.uuid
    );
    if (isFound) {
      updateData = selectedRowData?.filter((row) => row?.uuid !== item?.uuid);
    } else {
      updateData = [...selectedRowData, item];
    }
    if (updateData?.length > 0) {
      updateType.current = "batch";
    } else {
      updateType.current = "single";
    }

    setSelectedRowData(updateData);
  };

  const onClickItem = (newExpanded, item) => {
    setTimeout(() => {
      if (!preventClick.current) {
        setExpanded(newExpanded ? item?.uuid : false);
      } else {
        preventClick.current = false;
      }
    }, 0);
  };

  const onClickTransaction = async (data) => {
    if (data) {
      const item = await getTransactionById(data?.uuid);
      if (item) {
        store.dispatch(
          setTransactionsOverlayStatus({
            open: true,
            overlay_type: "drawer_modal_edit_form",
            anchor: "right",
            payload: {
              modalType: "edit",
              item,
            },
          })
        );
      }
    }
  };

  const handleChangeAll = (e) => {
    if (selectedRowData?.length > 0) {
      setSelectedRowData([]);
    } else {
      setSelectedRowData(rows?.results || []);
    }
  };

  const createNewRule = (e, item, element) => {
    e.stopPropagation();

    const titles = [...new Set(item?.items?.map((o1) => o1?.title))];

    let transactionsRow = {
      category: null,
      transaction_type: item?.key?.transaction_type === "inflow" ? 1 : 2,
    };
    if (element) {
      transactionsRow.title = element?.title;
      transactionsRow.note = element?.note;
      transactionsRow.bank_category_id = element?.bank_category;
    } else {
      transactionsRow.bank_category_id = item?.items?.[0]?.bank_category;
      if (titles?.length > 1) {
        transactionsRow.multipleTitles = titles;
      } else {
        transactionsRow.title = item?.items?.[0]?.title;
        transactionsRow.note = item?.items?.[0]?.note;
      }
    }

    categoryRef?.current?.createNewRules({
      transactionsRow,
    });
  };

  const onClickLink = () => {
    rulesRef.current?.openRulesListOverlay();
  };

  //render functions
  const Footer = (props) => {
    const { listLoading, rowCountState } = props;
    const onClickLeft = () => {
      page.current = page.current - 1;
      setRows(savedData.current[page.current]);
    };

    const onClickRight = () => {
      if (savedData.current?.[page.current + 1]?.next) {
        next.current = savedData.current[page.current + 1]?.next?.replace(
          "api/",
          ""
        );
        setRows(savedData.current[page.current + 1]);
      } else {
        next.current = BulkList?.data?.next.replace("api/", "");
        BulkList.refetch();
      }
      page.current = page.current + 1;
    };
    const isEmpty =
      !listLoading &&
      rowCountState === 0 &&
      savedData.current?.[page.current - 1]?.length === 0;
    return (
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: "-2rem",
          left: 0,
          right: 0,
          backgroundColor: Color.appThemeBg,
          zIndex: 1000,
          height: height,
        }}
      >
        {isEmpty ? null : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: height,
              borderTop: `1px solid ${Color.tailwind.slate[50]}`,
              backgroundColor: Color.appThemeBg,
            }}
          >
            <Button
              onClick={onClickLeft}
              disabled={page.current === 1}
              disableRipple
              disableTouchRipple
              disableElevation
              disableFocusRipple
              sx={{
                width: "2rem",
                minWidth: "auto",
                color: Color.black,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ChevronLeftIcon sx={{}} />
            </Button>
            <Button
              onClick={onClickRight}
              disabled={!rows?.next || rows?.results?.length <= 14}
              disableRipple
              disableTouchRipple
              disableElevation
              disableFocusRipple
              sx={{
                width: "2rem",
                mr: "0.9rem",
                ml: "1.3rem",
                minWidth: "auto",
                color: Color.black,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ChevronRightIcon />
            </Button>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Grid
      container
      style={{
        width: width,
        position: "relative",
        height: "calc(100% - 10rem)",
      }}
    >
      <Divider sx={{ width: width, height: 0 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "2rem",
          marginBottom: "1.5rem",
        }}
      >
        <Stack direction="row" alignItems={"center"} sx={{ gap: "1rem" }}>
          <BulkActionView
            toggle={toggle}
            categoryRef={categoryRef}
            rulesRef={rulesRef}
            selectedRowData={selectedRowData}
            resetKanban={resetKanban}
            tab={"bulkUnCatArray"}
            disabled={selectedRowData?.length === 0}
          />
          <ToggleView tab={tab} toggle={toggle} />
        </Stack>

        <Stack direction="row" alignItems={"center"} sx={{ gap: "1rem" }}>
          {selectedRowData?.length > 0 ? null : (
            <Tooltip
              title={t("You can select Transactions for bulk categorization")}
              placement="top"
            >
              <Alert
                severity="info"
                sx={{
                  ml: "3rem",
                  boxShadow: "none !important",
                  fontSize: "0.8rem",
                  minHeight: "2rem",
                  padding: "0.1rem 0.475rem",
                  fontFamily: Fonts.Text,
                  display: "inline-flex",
                  alignItems: "center",
                  alignSelf: "center",
                  backgroundColor: getTailwindColor("violet", 100),
                  color: getTailwindColor("violet", 700),
                  "& .MuiAlert-icon": {
                    fontSize: "1rem",
                    mr: {
                      xs: "0",
                      s1280: "0.5rem",
                    },
                    color: getTailwindColor("violet", 700),
                    alignItems: "center",
                  },
                  "& .MuiAlert-message": {
                    textAlign: "left",
                    py: "0rem",
                    display: { xs: "none", s1280: "inline-flex" },
                  },
                }}
              >
                {t("You can select Transactions for bulk categorization")}
              </Alert>
            </Tooltip>
          )}
          <ActionButton
            onClick={onClickLink}
            label={"Manage Rules"}
            buttontype="manage2"
            sx={{
              minWidth: "fit-content",
            }}
          />
        </Stack>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginBottom: "0.5rem",
        }}
      >
        <Grid item xs={1} sx={{ textAlign: "left", pl: "0.5rem" }}>
          {rows?.results?.length > 0 ? (
            <Checkbox
              checked={selectedRowData?.length === rows?.results?.length}
              indeterminate={
                selectedRowData?.length > 0 &&
                selectedRowData?.length !== rows?.results?.length
              }
              onChange={handleChangeAll}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            textAlign: "left",
            fontWeight: 600,
            fontSize: "0.9rem",
            color: getTailwindColor("slate", 800),
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {t("Title")}
        </Grid>
        <Grid
          item
          xs={1.5}
          sx={{
            textAlign: "left",
            fontWeight: 600,
            fontSize: "0.9rem",
            color: getTailwindColor("slate", 800),
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {t("Value")}
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            textAlign: "left",
            fontWeight: 600,
            fontSize: "0.9rem",
            color: getTailwindColor("slate", 800),
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {t("Data_Source")}
        </Grid>
        <Grid item xs={4.65}></Grid>
      </div>
      <Box
        sx={{
          height: "calc(100% - 10rem)",
          display: "flex",
          flexDirection: "Column",
          overflow: "auto",
          pb: "1rem",
          width: "100%",
          position: "relative",
          backgroundColor: "transparent",
          "& .actionBtn": {
            backgroundColor: theme.palette.color.slate[100],
            borderRadius: theme.borderRadius.main,
            minWidth: "2rem",
            p: "0.57rem",
            ml: "0.5rem",
            "&: hover": {
              backgroundColor: theme.palette.color.slate[200],
            },
            "& .actionBtnIcon": {
              backgroundColor: "transparent",
              color: theme.palette.color.slate[600],
              fontSize: "1rem",
              margin: 0,
            },
          },
          ...theme.thinScrollBar,
        }}
      >
        {!BulkList?.isFetching && rows?.results?.length === 0 ? (
          <div
            style={{
              width: "100%",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoDataInfoView
              tab={"Bulk"}
              backgroundColor={Color.tailwind.slate[50]}
            />
          </div>
        ) : null}
        {BulkList?.isFetching ? (
          <ComponentLoader
            loading
            hideNoDataPlaceholder
            isSkeleton
            skeltonSx={{
              transform: "scale(1,0.8)",
            }}
          />
        ) : null}
        {!BulkList?.isFetching && rows?.results?.length > 0
          ? rows?.results?.map((item) => {
              return (
                <AccordionItem
                  toggle={toggle}
                  isFinbanAccount={isFinbanAccount}
                  height={height}
                  key={item?.uuid}
                  item={item}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  selectedRowData={selectedRowData}
                  onSelectionModelChange={onSelectionModelChange}
                  onClickItem={onClickItem}
                  onClickAssign={onClickAssign}
                  onClickAssignSingle={onClickAssignSingle}
                  checkList={checkList}
                  onClickTransaction={onClickTransaction}
                  onClickAssignSuggestionCategory={
                    onClickAssignSuggestionCategory
                  }
                  preventClick={preventClick}
                  createNewRule={createNewRule}
                />
              );
            })
          : null}
      </Box>

      <Footer
        listLoading={BulkList.isFetching}
        rowCountState={rows?.results?.length || 0}
      />
    </Grid>
  );
};

export default BulkListView;

const AccordionItem = memo(
  ({
    toggle,
    isFinbanAccount,
    item,
    height,
    expanded,
    checkList,
    onClickItem,
    selectedRowData,
    onSelectionModelChange,
    onClickAssign,
    onClickAssignSingle,
    onClickTransaction,
    createNewRule,
    onClickAssignSuggestionCategory,
  }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const isExpanded = expanded === item?.uuid;
    const isButtonDisable = selectedRowData?.length !== 0;
    const is_recurring_rule = !!item?.recurring_rule;
    const isSingle = item?.items?.length === 1;
    const isChecked = checkList?.includes(item?.uuid);
    const isIncome = item?.items?.[0]?.gross_value >= 0;
    const titleList = useMemo(() => {
      const isMultiple =
        toggle?.includes("similarTitle") && item?.items.length > 1;
      return isMultiple ? item?.items?.[0]?.title : item?.key?.title;
    }, [item?.items, item?.key?.title, toggle]);

    const valueList = useMemo(() => {
      const isMultiple =
        toggle?.includes("diffValue") && item?.items.length > 1;
      const _valueList = [
        ...new Set(
          item?.items?.map((o1) => parseFloat(o1?.gross_value || 0).toFixed(0))
        ),
      ];
      const titleList = isMultiple
        ? `${_valueList
            ?.map((o1) =>
              formatAmount({
                amount: o1,
              })
            )
            ?.join(", ")}`
        : formatAmount({
            amount:
              item?.key?.gross_value || item?.key?.gross_value === 0
                ? item?.key?.gross_value
                : item?.items?.[0]?.gross_value,
          });
      return titleList;
    }, [item?.items, item?.key?.gross_value, toggle]);

    const dSList = useMemo(() => {
      const accountByDS = store?.getState()?.globalSlice?.accountByDS;
      const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
      const DSIDS = [...new Set(item?.items?.map((o1) => o1?.data_source))];
      let bankList = [];
      DSIDS?.forEach((ds) => {
        const account = accountByDS?.[ds]?.[0];
        const DS = dataSourceById?.[ds]?.[0];
        const title =
          (DS?.internal_dataset ? t("Internal data source") : "") ||
          DS?.title ||
          account?.bank_details?.title;
        bankList.push(title);
      });

      return `${bankList?.join(", ")}`;
    }, [item?.items, t]);

    const onChange = (e, isExpanded) => {
      e.stopPropagation();
      if (isSingle) {
        e.stopPropagation();
        onClickTransaction({ uuid: item?.items?.[0]?.uuid });
      } else {
        onClickItem(isExpanded, item);
      }
    };

    const onChangeCheckBox = (e) => {
      e.stopPropagation();
      onSelectionModelChange(e, item);
    };

    const onClickButton = (e) => {
      onClickAssign(e, item);
    };

    return (
      <MuiAccordion
        expanded={isExpanded}
        elevation={0}
        onChange={onChange}
        sx={{
          boxShadow: "none",
          mb: isSingle ? "0.35rem" : "0.65rem",
          backgroundColor: "transparent",
          "& .MuiCheckbox-root": {
            p: "0.5rem",
          },
        }}
      >
        <MuiAccordionSummary
          hideIcon={isSingle}
          zIndex={item?.items?.length + 3}
          sx={{
            position: "relative",
            minHeight: height,
            backgroundColor: alpha(getTailwindColor("slate", 50), 0.2),
            zIndex: item?.items?.length + 3,
            "&:hover": {
              backgroundColor: getTailwindColor("slate", 100),
            },
          }}
        >
          {isExpanded ? (
            <span
              style={{
                width: "0.5rem",
                height: height,
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                backgroundColor: getTailwindColor("purple", 800),
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
                zIndex: item?.items?.length + 4,
              }}
            />
          ) : null}

          {isExpanded || isSingle ? null : (
            <div
              style={{
                backgroundColor: isExpanded
                  ? getTailwindColor("slate", 50)
                  : Color.white,
                borderRadius: "6px",
                position: "absolute",
                top: 4,
                left: 5,
                right: 0,
                width: "calc(100% - 10px)",
                height: height,
                border: `1px solid ${Color.tailwind.slate[400]}`,
                zIndex: 1,
              }}
            />
          )}
          {isExpanded || isSingle ? null : (
            <div
              style={{
                backgroundColor: Color.white,
                borderRadius: "6px",
                position: "absolute",
                top: 2,
                left: 2,
                right: 0,
                width: "calc(100% - 4px)",
                height: height,
                border: `1px solid ${Color.tailwind.slate[400]}`,
                zIndex: 2,
              }}
            />
          )}
          <ListItem
            sx={{
              p: 0,
              zIndex: item?.items?.length + 3,
              display: "flex",
              alignItems: "center",
              backgroundColor: isExpanded
                ? getTailwindColor("slate", 50)
                : Color.white,
              borderRadius: "6px",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: height,
              border: `1px solid ${Color.tailwind.slate[400]}`,
              "&:hover": {
                backgroundColor: getTailwindColor("slate", 50),
              },
            }}
          >
            <Grid item xs={1} sx={{ pl: "0.5rem" }}>
              <Checkbox
                checked={isChecked}
                onClick={onChangeCheckBox}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ zIndex: item?.items?.length + 4 }}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: "0.5rem" }}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: 600,
                  fontSize: "0.85rem",
                  color: getTailwindColor("slate", 800),
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                }}
              >
                {titleList}
              </Typography>
              <Typography
                sx={{
                  color: getTailwindColor("slate", 500),
                  textAlign: "left",
                  fontSize: "0.75rem",
                  fontWeight: 500,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                }}
              >
                {item?.items?.[0]?.note}
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon={<BsArrowUpRightCircle />}
                  fontSize={"1rem"}
                  color={getTailwindColor(isIncome ? "green" : "red", 500)}
                  strokeWidth={2}
                  style={{
                    mr: "0.5rem",
                    transform: `rotate(${isIncome ? "0deg" : "90deg"})`,
                  }}
                ></Icon>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: 600,
                    fontSize: "0.85rem",
                    lineHeight: "1.5rem",
                    color: getTailwindColor("slate", 800),
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "block",
                  }}
                >
                  {" "}
                  {`${item?.items?.length} ${t("Transactions")}`}
                </Typography>
              </div>

              <Tooltip title={valueList}>
                <Typography
                  sx={{
                    color: getTailwindColor("slate", 500),
                    textAlign: "left",
                    fontSize: "0.75rem",
                    lineHeight: "0.75rem",
                    fontWeight: 500,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "block",
                    width: "fit-content",
                    maxWidth: "100%",
                  }}
                >
                  {valueList}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={1}>
              <Typography
                sx={{
                  color: getTailwindColor("slate", 800),
                  textAlign: "left",
                  fontSize: "0.85rem",
                  lineHeight: "1.5rem",
                  fontWeight: 600,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                  mb: "0.75rem",
                }}
              >
                {dSList}
              </Typography>
            </Grid>
            <Grid
              item
              xs={5.5}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                pr: "1.25rem",
              }}
            >
              {isFinbanAccount && item?.items?.[0]?.bank_category ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: "0.8rem",
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  {`${t("Suggestions")}: `}
                  <CategoryChip
                    onClick={(e) => onClickAssignSuggestionCategory(e, item)}
                    count="14"
                    height="1.8rem"
                    fontSize="0.7rem"
                    categoryId={item?.items?.[0]?.bank_category ?? null}
                    backgroundShade={100}
                    borderShade={400}
                    borderWidth={3}
                    borderType="dashed"
                    isBankCategory
                    isSuggestion
                    customColor="slate"
                    highlighColor
                    showTooltip
                    postView={
                      <p
                        style={{
                          backgroundColor: theme.palette?.color?.slate[200],
                          borderRadius: theme.borderRadius.main,
                          paddingInline: "0.5rem",
                          paddingBlock: "0.35rem",
                          marginBlock: 0,
                          fontSize: "0.7rem",
                          lineHeight: "0.7rem",
                        }}
                      >
                        {`${t("Add")}`}
                      </p>
                    }
                    style={{
                      display: "flex",
                    }}
                  />
                </div>
              ) : null}
              <TailwindButton
                disable={isButtonDisable || is_recurring_rule}
                onClick={onClickButton}
                icon={
                  <LocalOfferIcon
                    sx={{
                      fontSize: "1rem",
                      mr: "0.5rem",
                    }}
                  />
                }
                tooltip={
                  isButtonDisable
                    ? t("bulk_list_assign_btn_disable_text")
                    : isSingle
                      ? t("Assign Category")
                      : t("Assign Category to all")
                }
                text={
                  isButtonDisable
                    ? t("bulk_list_assign_btn_disable_text")
                    : isSingle
                      ? t("Assign Category")
                      : t("Assign Category to all")
                }
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette?.color.slate[100],
                  border: `1px solid ${theme.palette?.color?.slate[300]}`,
                  color: theme.palette?.color.slate[600],
                  cursor:
                    isButtonDisable || is_recurring_rule
                      ? "not-allowed"
                      : "pointer",
                  fontWeight: 500,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  "&:hover": {
                    border: `1px solid ${theme.palette?.color?.slate[300]}`,
                    backgroundColor: theme.palette?.color.slate[200],
                  },
                  "&.Mui-disabled": {
                    opacity: 0.8,
                  },
                }}
              />

              <TailwindButton
                disabled={is_recurring_rule}
                variant="outlined"
                onClick={(e) => createNewRule(e, item)}
                icon={<PiMagicWandFill style={{ fontSize: "1.1rem" }} />}
                tooltip={t("bulk_list_action_add_manual_rule_tooltip")}
                text={t("bulk_list_action_add_manual_rule")}
                color={theme.palette.color.white}
                sx={{
                  backgroundImage: `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
                  color: theme.palette.color.white,
                  gap: "0.5rem",
                  border: `3px solid ${theme.palette?.primary.main}`,
                  "&:hover": {
                    backgroundImage: `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
                    border: `3px solid ${theme.palette?.primary.main}`,
                  },
                }}
              />
            </Grid>
          </ListItem>
        </MuiAccordionSummary>
        <MuiAccordionDetails
          sx={{
            height: `${3 * item?.items?.length}rem`,
            pb: "0.5rem",
            maxHeight: "25rem",
            overflow: "auto",
            position: "relative",
            backgroundColor: "transparent",
            ...theme.thinScrollBar,
          }}
        >
          {isExpanded &&
            item?.items?.map((element, index) => {
              return (
                <RenderIfVisible
                  key={element?.uuid}
                  initialVisible={index < 8}
                  defaultHeight={height}
                  visibleOffset={500}
                  stayRendered={true}
                  rootElement="section"
                  placeholderElement="div"
                >
                  <ListItem
                    onClick={() => onClickTransaction(element)}
                    sx={{
                      p: 0,
                      zIndex: item?.items?.length + 3 - (index + 1),
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      border: `1px solid ${Color.tailwind.slate[400]}`,
                      height: height,
                      position: "absolute",
                      top: `${3 * index - 0.5}rem`,
                      left: 0,
                      right: 0,
                      backgroundColor: Color.white,
                      borderRadius: "6px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: getTailwindColor("slate", 50),
                      },
                    }}
                  >
                    <Grid item xs={1} sx={{ pl: "1rem" }}>
                      <Typography
                        sx={{
                          color: getTailwindColor("slate", 500),
                          textAlign: "left",
                          fontSize: "0.75rem",
                          fontWeight: 500,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          mt: "0.5rem",
                          pl: "0.75rem",
                          display: "block",
                        }}
                      >
                        {element?.due_date
                          ? format(new Date(element?.due_date), "MMM dd ,yyyy")
                          : ""}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} sx={{ pr: "0.5rem" }}>
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: 600,
                          fontSize: "0.85rem",
                          color: getTailwindColor("slate", 800),
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          mt: "0.5rem",
                          display: "block",
                        }}
                      >
                        {element?.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: getTailwindColor("slate", 500),
                          textAlign: "left",
                          fontSize: "0.75rem",
                          fontWeight: 500,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {element?.note}
                      </Typography>
                    </Grid>
                    <Grid item xs={1.55} sx={{ pr: "0.5rem" }}>
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: 600,
                          fontSize: "0.85rem",
                          color: getTailwindColor("slate", 800),
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          mt: "0.5rem",
                          display: "block",
                        }}
                      >
                        {formatAmount({
                          amount: element?.gross_value,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        sx={{
                          color: getTailwindColor("slate", 800),
                          textAlign: "left",
                          fontSize: "0.85rem",
                          fontWeight: 600,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          mt: "0.5rem",
                          display: "block",
                        }}
                      >
                        {dSList}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={5.5}
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        pt: "0.25rem",
                        pr: item?.items?.length > 9 ? "0.5rem" : "1.25rem",
                      }}
                    >
                      {isFinbanAccount && element?.bank_category ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            fontSize: "0.8rem",
                            fontFamily: theme.typography.fontFamily,
                          }}
                        >
                          {`${t("Suggestions")}: `}
                          <CategoryChip
                            onClick={(e) =>
                              onClickAssignSuggestionCategory(e, {
                                ...item,
                                items: [element],
                              })
                            }
                            count="14"
                            height="1.8rem"
                            fontSize="0.7rem"
                            categoryId={element?.bank_category ?? null}
                            backgroundShade={100}
                            borderShade={400}
                            borderWidth={3}
                            borderType="dashed"
                            isBankCategory
                            isSuggestion
                            customColor="slate"
                            highlighColor
                            showTooltip
                            postView={
                              <p
                                style={{
                                  backgroundColor:
                                    theme.palette?.color?.slate[200],
                                  borderRadius: theme.borderRadius.main,
                                  paddingInline: "0.5rem",
                                  paddingBlock: "0.35rem",
                                  marginBlock: 0,
                                  fontSize: "0.7rem",
                                  lineHeight: "0.7rem",
                                }}
                              >
                                {`${t("Add")}`}
                              </p>
                            }
                            style={{
                              display: "flex",
                            }}
                          />
                        </div>
                      ) : null}
                      <TailwindButton
                        disable={isButtonDisable || is_recurring_rule}
                        tooltip={
                          isButtonDisable
                            ? t("bulk_list_assign_btn_disable_text")
                            : t("Assign Category")
                        }
                        onClick={(e) =>
                          onClickAssignSingle(e, {
                            ...item,
                            items: [element],
                          })
                        }
                        text={
                          isButtonDisable
                            ? t("bulk_list_assign_btn_disable_text")
                            : t("Assign Category")
                        }
                        variant="outlined"
                        sx={{
                          backgroundColor: theme.palette?.color.slate[100],
                          border: `1px solid ${theme.palette?.color?.slate[300]}`,
                          color: theme.palette?.color?.slate[600],
                          cursor:
                            isButtonDisable || is_recurring_rule
                              ? "not-allowed"
                              : "pointer",
                          fontWeight: 500,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          "&:hover": {
                            backgroundColor: theme.palette?.color?.slate[200],
                          },
                          "&.Mui-disabled": {
                            opacity: 0.8,
                          },
                        }}
                      />

                      <TailwindButton
                        disabled={is_recurring_rule}
                        variant="outlined"
                        onClick={(e) => createNewRule(e, item, element)}
                        icon={
                          <PiMagicWandFill style={{ fontSize: "1.1rem" }} />
                        }
                        tooltip={t("bulk_list_action_add_manual_rule_tooltip")}
                        text={t("bulk_list_action_add_manual_rule")}
                        color={theme.palette.color.white}
                        sx={{
                          backgroundImage: `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
                          color: theme.palette.color.white,
                          gap: "0.5rem",
                          border: `3px solid ${theme.palette?.primary.main}`,
                          "&:hover": {
                            backgroundImage: `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
                            border: `3px solid ${theme.palette?.primary.main}`,
                          },
                        }}
                      />
                    </Grid>
                  </ListItem>
                </RenderIfVisible>
              );
            })}
        </MuiAccordionDetails>
      </MuiAccordion>
    );
  },
  (prev, next) =>
    prev?.selectedRowData?.length === next?.selectedRowData?.length &&
    prev?.expanded === next?.expanded &&
    prev?.preventClick?.current === next?.preventClick?.current &&
    prev?.height === next?.height &&
    prev?.items?.length === next?.items?.length &&
    prev?.item?.uuid === next?.item?.uuid
);

const BulkActionView = ({
  toggle,
  rulesRef,
  categoryRef,
  selectedRowData,
  resetKanban,
  tab,
  disabled,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isSubscriptionValid] = useSubscriptions();

  const form_type = useRef(null);
  const showInfo = useRef(false);
  const itemRow = useRef({});

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  //states
  const [actionList, setActionList] = useState([]);

  //functions
  const handleClickSettings = (e, uuid, popupState) => {
    //delete
    if (uuid === 1) {
      popupState?.close();
      dispatch(
        setPopupStatus3({
          id: "simple-popper",
          open: true,
          overlay_type: "delete",
          onConfirm: onDelete,
          payload: {
            title: t("Attention"),
            message: t(
              "Are you sure that you want to delete this transaction?"
            ),
            confirmText: t("Ok"),
            hideCancel: true,
          },
        })
      );
    }

    //clone
    // note : clone we do via backend , rest data will be copied in backend
    if (uuid === 2) {
      if (isSubscriptionValid({ showMessage: true })) {
        popupState?.close();
        dispatch(setStageLoadingText("process_transactions_is_being_cloned"));
        let array = [];
        selectedRowData.forEach((element) => {
          element.items.forEach((item) => {
            array.push({
              uuid: item?.uuid,
              source: 1,
              transaction_system: null,
              account: null,
              reconciled: null,
              reconciled_by: null,
              last_modified_rule: null,
              state_before_split: null,
              parent: null,
              data_source:
                item?.source === 1
                  ? item?.data_source
                  : dataSetData?.internal_data_source,
            });
          });
        });

        cloneBatch(array);
      }
    }

    //Categorize
    if (uuid === 3) {
      let numbers = [];
      if (toggle?.includes("diffValue")) {
        selectedRowData.forEach((element) => {
          [...new Set(element.items?.map((o1) => o1.gross_value))].forEach(
            (item) => numbers.push(item)
          );
        });
      } else {
        selectedRowData.forEach((element) => {
          numbers.push(element.key?.gross_value);
        });
      }

      const areAllPositive = numbers.every((number) => Number(number) >= 0);
      const areAllNegative = numbers.every((number) => Number(number) < 0);
      let type = 3;
      if (areAllPositive) {
        type = 1;
      }
      if (areAllNegative) {
        type = 2;
      }
      const rowItem = selectedRowData?.[0];
      itemRow.current.transaction_type = type;
      itemRow.current.title = rowItem?.key?.title;

      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: e.currentTarget,
          overlay_type: "category",
          payload: {
            hideUncategorize: true,
            type,
            onClickCategoryTitle,
          },
        })
      );
    }

    //uncategorize
    if (uuid === 5) {
      let array = [];
      popupState?.close();
      selectedRowData.forEach((item) => {
        array.push({
          uuid: item.uuid,
          category: null,
        });
      });
      updateCardsBatch(array);
    }

    //booked
    if (uuid === 13) {
      popupState?.close();
      updateBatchValue({ state: "Booked" });
    }

    //add manual rule
    if (uuid === 15) {
      let titles = [];
      popupState?.close();
      selectedRowData?.forEach((element) => {
        element?.items?.forEach((item) => {
          if (!titles.includes(item?.title)) {
            titles.push(item?.title);
          }
        });
      });
      let transactionsRow = {
        category: null,
        transaction_type:
          selectedRowData?.[0]?.key?.transaction_type === "inflow" ? 1 : 2,
      };
      if (titles?.length > 1) {
        transactionsRow.multipleTitles = titles;
      } else {
        transactionsRow.title = titles?.[0];
      }

      categoryRef?.current?.createNewRules({
        transactionsRow,
      });
    }

    if (uuid === 4 || uuid === 7 || uuid === 8 || uuid === 9 || uuid === 10) {
      form_type.current = "state";
      popupState?.close();
      if (uuid === 7) {
        form_type.current = "cost_unit";
      }
      if (uuid === 8) {
        form_type.current = "scenario";
      }
      if (uuid === 9) {
        form_type.current = "due_date";
      }
      if (uuid === 10) {
        form_type.current = "invoice_date";
      }
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: true,
          overlay_type: "form",
          payload: {
            type: form_type.current,
            onSave: onSaveForm,
          },
        })
      );
    }
  };

  const onDelete = async () => {
    let uuids = [];
    selectedRowData.forEach((element) => {
      element.items.forEach((item) => {
        uuids.push(item?.uuid);
      });
    });

    deleteBatch(uuids);
  };

  const onClickCategoryTitle = (e, item) => {
    let array = [];

    selectedRowData.forEach((element) => {
      element.items.forEach((ele) => {
        array.push({
          uuid: ele?.uuid,
          category: item?.uuid === `unCategorized_category` ? null : item?.uuid,
        });
      });
    });
    updateCardsBatch(array);
    closeCategoryOverlay();
  };

  const onSaveForm = (obj) => {
    let item = {};
    if (form_type.current === "cost_unit") {
      item = {
        [form_type.current]: obj.uuid,
      };
    }
    if (form_type.current === "state" || form_type.current === "scenario") {
      item = {
        [form_type.current]: obj.title,
      };
    }

    if (form_type.current === "invoice_date") {
      item = {
        [form_type.current]: obj[form_type.current],
      };
    }
    if (form_type.current === "due_date") {
      item = {
        [form_type.current]: obj?.[form_type.current],
      };
      if (obj?.[form_type.current]) {
        item = {
          invoice_date: null,
        };
      }
    }
    updateBatchValue(item);
    closeFormOverlay();
    closeCategoryOverlay();
  };

  const updateBatchValue = (item) => {
    let array = [];
    selectedRowData.forEach((element) => {
      element.items.forEach((ele) => {
        array.push({
          uuid: ele.uuid,
          ...item,
        });
      });
    });
    updateCardsBatch(array);
    closeCategoryOverlay();
  };

  const closeFormOverlay = () => {
    form_type.current = null;
    dispatch(setPopupStatus4(null));
  };

  const closeCategoryOverlay = () => {
    dispatch(setPopupStatus4(null));
  };

  //apis
  const deleteBatch = async (uuids) => {
    dispatch(setStageLoadingText("process_transactions_is_being_deleted"));
    let isDeleted = await deleteBatchTransactions(uuids);
    if (isDeleted) {
      resetKanban();
      enqueueSnackbar(t("Cards Deleted Successfully"));
    }
    dispatch(setPopupStatus4(null));
    dispatch(setPopupStatus3(null));
    dispatch(setStageLoadingText(null));
  };

  const cloneBatch = async (array) => {
    dispatch(setStageLoadingText("process_transactions_is_being_cloned"));
    let response = await cloneBatchTransactions(array);
    if (response) {
      resetKanban();
      enqueueSnackbar(t("Cards Cloned Successfully"));
    }
    dispatch(setPopupStatus4(null));
    dispatch(setPopupStatus3(null));
    dispatch(setStageLoadingText(null));
  };

  const updateCardsBatch = async (array) => {
    itemRow.current.category = array?.[0]?.category;
    dispatch(setStageLoadingText("process_transactions_is_being_updated"));
    const response = await updateBatchTransactions(array);
    const rulesError = response?.find((o1) =>
      o1?.category?.[0]?.includes("Cannot set category")
    );

    if (rulesError) {
      dispatch(setStageLoadingText(null));
      rulesRef.current?.onOpen({
        payload: array,
        response,
        title: itemRow.current?.title,
        category: itemRow.current?.category,
        transaction_type: itemRow.current?.transaction_type,
        message: rulesError?.category?.[0],
        rule_type: rulesError?.type?.[0],
        transaction_rule: rulesError?.transaction_rule?.[0],
      });
    } else {
      if (response) {
        resetKanban();
        dispatch(setStageLoadingText(null));
      }
    }
  };

  //life cycles
  useDebounce(
    () => {
      if (selectedRowData?.length > 0 && tab) {
        const findBooked = selectedRowData?.find((o1) => o1.booked);
        const find_recurring_rule = selectedRowData?.find(
          (o1) => o1.recurring_rule
        );
        const transaction_type = [
          ...new Set(selectedRowData?.map((o1) => o1.key?.transaction_type)),
        ];
        let IDs = [4, 5, 6, 12, 14, 16, 17];
        showInfo.current = false;
        if (findBooked) {
          showInfo.current = "bulk_action_disable_actions_info_reason_booked";
          IDs.push(1);
          IDs.push(7);
          IDs.push(8);
          IDs.push(9);
          IDs.push(10);
          IDs.push(11);
        }
        if (transaction_type?.length > 1) {
          showInfo.current =
            "bulk_action_disable_actions_info_reason_mixed_transactions";
          IDs.push(3);
          IDs.push(15);
        }
        if (find_recurring_rule) {
          //disable all
          //reason: if user do action we update only transactions
          //here we also need to update linked recurring rule
          showInfo.current =
            "bulk_action_disable_actions_info_reason_recurring_rule";
          IDs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
        }

        setActionList(IDs);
      }
    },
    500,
    [selectedRowData, tab],
    true
  );

  return (
    <Tooltip
      title={disabled ? t("list_view_bulk_actions_disabled_message") : ""}
      placement="top"
    >
      <div>
        <PopupState
          variant="popper"
          id="demo-mutiple-checkbox2"
          popupId="demo-popup-popover"
        >
          {(popupState) => (
            <>
              <Button
                {...bindTrigger(popupState)}
                disabled={disabled}
                startIcon={
                  <Icon
                    icon={<HiOutlineLightningBolt />}
                    fontSize={"1.2rem"}
                    color={
                      disabled ? theme.palette.color.slate[400] : Color.white
                    }
                  ></Icon>
                }
                sx={{
                  height: "1.875rem",
                  p: "1.25rem",
                  mr: "1rem",
                  color: disabled
                    ? theme.palette.color.slate[300]
                    : Color.white,
                  backgroundColor: disabled
                    ? theme.palette.color.slate[100]
                    : theme.palette.primary.dark,
                  borderRadius,
                  fontWeight: "500 !important",
                  textTransform: "initial",
                  fontSize: "1rem",
                  width: {
                    xs: "15rem",
                    small: "13rem",
                    s1080: "11rem",
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.primary.darkHover,
                  },
                }}
              >
                {t("Run bulk action")}
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      borderRadius: 1.5,
                      boxShadow: Constant.OverlayBoxShadow,
                    },
                  },
                }}
                sx={{
                  ml: "0.5rem",
                }}
              >
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "fit-content",
                    minHeight: "3.125rem",
                    width: "fit-content",
                    minWidth: "18.75rem",
                    position: "relative",
                  }}
                >
                  {showInfo.current ? (
                    <Typography
                      variant="caption"
                      fontWeight={"fontWeightMediumBold"}
                      sx={{
                        color: Color.tailwind.slate[500],
                        mx: "1.25rem",
                        mb: `0.5rem`,
                      }}
                    >
                      {t(showInfo.current)}
                    </Typography>
                  ) : null}
                  {actionListOption?.map((item) => {
                    const disabled = actionList?.includes(item?.uuid);
                    return (
                      <ListItem
                        key={`key-${item?.uuid}`}
                        disableGutters
                        disablePadding
                        onClick={
                          disabled
                            ? undefined
                            : (e) =>
                                handleClickSettings(e, item?.uuid, popupState)
                        }
                      >
                        <ListItemButton
                          disabled={disabled}
                          sx={{
                            fontFamily: Fonts?.Text,
                            "&:hover": {
                              backgroundColor: alpha(
                                Color.tailwind.slate[500],
                                0.1
                              ),
                              color: Color.tailwind.slate[500],
                              "& span": {
                                fontWeight: 500,
                                color: Color.tailwind.slate[500],
                              },
                              "& svg": {
                                color: Color.tailwind.slate[500],
                              },
                            },
                          }}
                        >
                          <ListItemIcon>{item?.icon}</ListItemIcon>
                          <ListItemText primary={t(item?.title)} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Popover>
            </>
          )}
        </PopupState>
      </div>
    </Tooltip>
  );
};

const ToggleView = ({ toggle, tab, color = "slate" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //functions
  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      nested_key: tab,
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const handleToggle = (name, checked) => {
    let toggleData = toggle ?? [];
    let data = toggleData?.slice();
    if (checked) {
      if (!data?.includes(name)) {
        data.push(name);
      }
    } else {
      data = toggle?.filter((o1) => o1 !== name);
    }

    updateFilterObj({ toggle: data });
  };

  return (
    <Box
      sx={{
        display: "flex",
        ml: "0.5rem",
        width: "fit-content",
        alignItems: "center",
        justifyContent: "space-between",
        color: getTailwindColor(color, 500),
        "& .MuiButtonBase-root": {
          color: `${getTailwindColor(color, 500)} !important`,
        },

        "& .MuiCheckbox-root": {
          padding: 0,
          mr: "0.5rem",
        },
        "& b": {
          // mx: "0.25rem",
        },
      }}
    >
      <span style={{ display: "inline-flex", alignItems: "flex-start" }}>
        <Checkbox
          checked={!!toggle?.includes("similarTitle")}
          onChange={(e) => handleToggle("similarTitle", e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Tooltip title={t("list_filter_title_tooltip")}>
          <span
            style={{
              fontSize: "0.8rem",
              fontFamily: Fonts.Text,
              color: getTailwindColor(color, 500),
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              gap: "0.25rem",
              width: "14rem",
            }}
          >
            <Translate
              i18nkey={"bulk_title_filter_text"}
              values={{
                text: toggle?.includes("similarTitle")
                  ? t("Similar Title")
                  : t("Same Title"),
              }}
            />
          </span>
        </Tooltip>
      </span>
      <span
        style={{
          marginLeft: "0.5rem",
          display: "inline-flex",
          alignItems: "flex-start",
        }}
      >
        <Checkbox
          checked={!!toggle?.includes("diffValue")}
          onChange={(e) => handleToggle("diffValue", e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Tooltip title={t("list_filter_value_tooltip")}>
          <span
            style={{
              fontSize: "0.8rem",
              color: getTailwindColor(color, 500),
              fontFamily: Fonts.Text,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              gap: "0.25rem",
              width: "18rem",
            }}
          >
            <Translate
              i18nkey={"bulk_value_filter_text"}
              values={{
                text: toggle?.includes("diffValue")
                  ? t("Different Values")
                  : t("Same Values"),
              }}
            />
          </span>
        </Tooltip>
      </span>
    </Box>
  );
};
