import {
  FormControlLabel,
  DialogActions,
  DialogContent,
  Typography,
  Checkbox,
  Dialog,
  Fade,
  Box,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useEffect, useMemo, useRef, useState } from "react";
// import PublicIcon from "@mui/icons-material/Public";
import { BsCurrencyExchange } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TailwindButton from "../../../components/Overlay/TailwindButton";
import TitleInput from "../../../components/Overlay/TitleInput";
import AmountView from "../../../components/Overlay/AmountView";
import DropDown from "../../../components/Overlay/DropDown";
import { Color, Constant, Fonts } from "../../../Helper";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";
import DSImage from "./DSImage";

// const countryByCode = _.groupBy(
//   initialData.euro_countries,
//   ({ market }) => market
// );

const TinkDialogView = ({ open, handleClickClose, cell }) => {
  const { t } = useTranslation();

  const dSObj = useRef(cell);
  const isDataUpdated = useRef(false);

  //redux
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);
  const isDevMode = useSelector((state) => state.commonSlice?.isDevMode);

  //state
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  let tooltip = "";
  if (dSObj.current?.ds?.internal_dataset) {
    tooltip = t("Internal data source tooltip");
  } else if (dSObj.current?.acc?.bank_details?.title) {
    tooltip = `${dSObj.current?.acc?.bank_details?.title}${
      dSObj.current?.acc?.iban ? ` / ${dSObj.current?.acc?.iban}` : ""
    }`;
  } else {
    tooltip = dSObj.current?.ds?.title;
  }
  const currencies = useMemo(
    () =>
      Constant?.Currencies?.map((item) => {
        return {
          uuid: item?.uuid,
          code: item?.code,
          name: t(item?.name),
          symbol: item?.symbol,
        };
      }),
    [t]
  );
  //api
  const updateDSByID = async (id, obj) => {
    await APICall("patch", EndPoints.integrations + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          handleClose(true);
        }
      }
    );
  };

  const updateAccountByID = async (id, obj) => {
    await APICall("patch", EndPoints.accounts + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  //function
  const handleClose = (doUpdate = false) => {
    setError(null);
    setLoader(false);
    dSObj.current = null;
    handleClickClose(doUpdate);
  };

  const onClose = () => {
    handleClose();
  };

  const onClickOutside = () => {
    if (dSObj.current?.modalType === "Edit" && isDataUpdated.current) {
      let integration_id = dSObj.current?.ds?.uuid;
      updateDSByID(integration_id, {
        title: dSObj.current?.ds?.title,
      });
    } else {
      handleClose();
    }
  };

  const handleClickAdd = () => {
    let integration_id = dSObj.current?.ds?.uuid;
    if (!dSObj.current?.ds?.currency) {
      setError({ ...error, currency: "please select currency" });
      return false;
    }
    // if (dSObj.current?.modalType === "Add" && !dSObj.current?.market) {
    //   setError({ ...error, market: "please select country" });
    //   return false;
    // }
    // Call the function to close the dialog.
    setLoader(true);

    if (!dSObj.current?.ds?.title?.trim()) {
      // This function is used to close the dialog when the user clicks outside of it.
      let count =
        dataSource?.filter((o1) => o1?.title.includes(""))?.length ?? 0;
      dSObj.current = {
        ...dSObj.current,
        ds: {
          ...dSObj.current?.ds,
          // If the data source is being edited and has been updated, update it.
          title: `${t("Bank Account ")} ${count > 0 ? `(${count})` : ""}`,
        },
      };
    }
    if (dSObj.current?.modalType === "Edit" && isDataUpdated.current) {
      updateDSByID(integration_id, {
        // Otherwise, close the dialog.
        title: dSObj.current?.ds?.title,
      });
      if (dSObj.current?.account) {
        updateAccountByID(dSObj.current?.account?.uuid, dSObj.current?.account);
      }
    }
  };

  const handleEditDs = (e) => {
    const { name, value } = e.target;
    let obj = { [name]: value };
    setError(null);
    setItem({ ...item, ...obj });
    isDataUpdated.current = true;
    dSObj.current = {
      ...dSObj.current,
      ds: { ...dSObj.current?.ds, ...obj },
    };
  };

  const onChangeCurrency = (e, value) => {
    setError(null);
    isDataUpdated.current = true;
    setItem({ ...item, currency: value?.code });
    dSObj.current = {
      ...dSObj.current,
      ds: { ...dSObj.current?.ds, currency: value?.code },
    };
  };

  // const onChangeMarket = (e, value) => {
  //   setError(null);
  //   isDataUpdated.current = true;
  //   setItem({ ...item, market: value?.market });
  //   dSObj.current = {
  //     ...dSObj.current,
  //     market: value?.market,
  //   };
  // };

  const handleEditAccountLimit = (originalValue) => {
    setError(null);
    isDataUpdated.current = true;
    setItem({ ...item, limit: originalValue });
    dSObj.current = {
      ...dSObj.current,
      account: {
        ...dSObj.current?.account,
        uuid: dSObj.current?.acc?.uuid,
        limit: originalValue,
      },
    };
  };

  const handleChangeCheckBox = (event) => {
    setItem({
      ...item,
      show_limit: event.target.checked,
    });
    dSObj.current = {
      ...dSObj.current,
      account: {
        ...dSObj.current?.account,
        uuid: dSObj.current?.acc?.uuid,
        show_limit: event.target.checked,
      },
    };
    isDataUpdated.current = true;
  };

  //lifecycle
  useEffect(() => {
    if (open) {
      isDataUpdated.current = false;
      if (dSObj.current?.modalType === "Edit" && dSObj.current?.ds?.uuid) {
        const account = accountByDS?.[dSObj?.current?.ds?.uuid]?.[0] || null;
        setItem({
          ...item,
          title: dSObj.current?.ds?.title,
          currency: dSObj.current?.ds?.currency,
          limit: account?.limit,
          show_limit: account?.show_limit,
        });
      }
    }
  }, [open, cell]);

  return (
    <Dialog
      open={!!open}
      onClose={onClickOutside}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          minWidth: "50rem",
          maxWidth: "none",
          width: "auto",
        },
      }}
      sx={{
        zIndex: 1303,
        "& button": {
          textTransform: "capitalize",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "4rem",
          pl: "1rem",
          backgroundColor: Color.HeaderBG,
        }}
      >
        <DSImage
          ds_uuid={dSObj.current?.ds?.uuid}
          integration_title={dSObj.current?.ds?.note}
          sx={{
            width: "4rem",
            height: "3rem",
          }}
        />

        <Typography
          sx={{
            fontFamily: Fonts.Text,
            fontSize: "1rem",
            fontWeight: 500,
            ml: "1rem",
            color: Color.tailwind.purple[950],
          }}
        >
          {tooltip}
        </Typography>
      </Box>

      <DialogContent sx={{ height: "27rem", backgroundColor: Color.BodyBG }}>
        <Box
          display="flex"
          alignItems={"center"}
          width="100%"
          pl={"2.5rem"}
          mb="1rem"
        >
          <TitleInput
            label={t("Title")}
            name="title"
            value={item?.title || ""}
            onChange={handleEditDs}
            helperText={error?.title}
            error={Boolean(error?.title)}
            hideTitle
            likeGoogle
            variant="filled"
          />
        </Box>

        <Box display="flex" alignItems={"center"} width="100%" mb="1rem">
          <BsCurrencyExchange
            style={{ fontSize: "1.5rem", marginRight: "1rem" }}
          />
          <DropDown
            disableClearable={true}
            label={t("Currency")}
            disabled={!isDevMode && dSObj.current?.modalType === "Edit"}
            hideTitle
            likeGoogle
            variant="filled"
            helperText={error?.currency}
            error={Boolean(error?.currency)}
            value={currencies.find((o1) => o1.code === item?.currency) ?? null}
            onChange={onChangeCurrency}
            options={currencies}
            getOptionLabel={(option) => option.symbol + " " + option.name ?? ""}
            renderOption={(option) => option.symbol + " " + option.name ?? ""}
          />
        </Box>

        {/* {dSObj.current?.modalType === "Add" ? (
          <Box display="flex" alignItems={"center"} width="100%" mb="1rem">
            <PublicIcon sx={{ mr: "1rem" }} />
            <DropDown
              disableClearable={true}
              label={t("Country")}
              disabled={dSObj.current?.modalType === "Edit"}
              hideTitle
              likeGoogle
              variant="filled"
              helperText={error?.market}
              error={Boolean(error?.market)}
              value={countryByCode?.[item?.market]?.[0] || null}
              onChange={onChangeMarket}
              options={initialData.euro_countries}
              getOptionLabel={(option) => option.country ?? ""}
              renderOption={(option) => option.country ?? ""}
            />
          </Box>
        ) : null} */}

        <Box display="flex" alignItems={"center"} width="100%" mb="1rem">
          <ArrowUpwardIcon sx={{ mr: "1rem" }} />
          <AmountView
            hideTitle
            likeGoogle
            defaultValue={item?.limit}
            onBlur={handleEditAccountLimit}
            label={t("Limit")}
            name="limit"
            variant="filled"
            currency={item?.currency}
            mb={0}
          />
        </Box>
        <Box display="flex" alignItems={"center"} width="100%" mb="1rem">
          <FormControlLabel
            disabled={!Boolean(item?.limit)}
            control={
              <Checkbox
                checked={Boolean(item?.show_limit)}
                onChange={handleChangeCheckBox}
                color="primary"
              />
            }
            label={t("Show Limit")}
            sx={{
              width: "fit-content",
              ml: "1.8rem",
              "& .MuiTypography-root": {
                color: "color.description",
                fontSize: "0.9rem",
                fontFamily: Fonts.Text,
                fontWeight: 500,
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: Color.FooterBG, p: "1.5rem" }}>
        <TailwindButton type="cancel" text={t("Cancel")} onClick={onClose} />
        <TailwindButton
          text={t(dSObj.current?.modalType === "Edit" ? "Save" : "Add")}
          onClick={loader ? undefined : handleClickAdd}
          loading={loader}
          disable={
            dSObj.current?.modalType === "Edit" && !isDataUpdated.current
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default TinkDialogView;
