import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItem,
  useTheme,
  Popover,
  Tooltip,
  Button,
  List,
} from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useContext, useMemo, useRef, useState } from "react";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import _ from "underscore";

import {
  getValidCategoryAfterStateUpdate,
  updateBatchTransactions,
  cloneBatchTransactions,
  formatDateToLocal,
} from "../../../../Helper/data";
import {
  setPopupStatus3,
  setPopupStatus4,
} from "../../../../store/slices/datasets";
import { setStageLoadingText } from "../../../../store/slices/appmain";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import useSubscriptions from "../../../../hooks/useSubscriptions";
import { GlobalContext } from "../../../../GlobalContextWrapper";
import EndPoints from "../../../../APICall/EndPoints";
import initialData from "./../../../../Helper/data";
import Icon from "../../../../components/Icon";
import { Constant } from "../../../../Helper";
import APICall from "../../../../APICall";

const TransactionViewSettingPopOver = ({
  selectedRowData,
  resetKanban,
  tab,
}) => {
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const dispatch = useDispatch();

  const showInfo = useRef(false);
  const form_type = useRef(null);
  const itemRow = useRef({});
  const selectedUuid = useRef(null);
  const actionInfo = useRef(null);

  const rulesRef = globalContext?.rulesRef;
  const seqFunctionRef = globalContext?.seqFunctionRef;
  const categoryRef = globalContext?.categoryRef;
  const disabled = !selectedRowData || selectedRowData?.length === 0;
  let actionListOption = useMemo(() => {
    if (tab === "staff") {
      return initialData?.actionListOption?.filter(
        (o1) => ![6, 7, 9, 10, 11, 12, 13, 14, 15].includes(o1?.uuid)
      );
    } else {
      return initialData?.actionListOption?.filter(
        (o1) => ![6, 17].includes(o1?.uuid)
      );
    }
  }, [tab]);

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const isDevMode = useSelector((state) => state.commonSlice?.isDevMode);

  //states
  const [actionList, setActionList] = useState([]);

  //life cycles
  useDebounce(
    () => {
      if (selectedRowData?.length > 0) {
        let IDs = [];
        let isInflow = false;
        let isOutflow = false;
        if (tab === "staff") {
          IDs = [7, 9, 10, 11, 12, 13, 14, 15];
        } else if (tab === "plannedArray") {
          let isLoan = selectedRowData?.find(
            (o1) =>
              o1.recurring_type === "loan" || o1.recurring_type === "leasing"
          );
          const isStaff = selectedRowData?.filter(
            (o1) =>
              o1.recurring_type === "loan" ||
              o1.recurring_type === "leasing" ||
              o1.recurring_type === "employee"
          );
          IDs = [7, 9, 10, 11, 12, 13, 15];
          if (isStaff?.length !== selectedRowData.length) {
            IDs.push(4);
          }

          if (isLoan) {
            IDs.push(14);
          }
        } else {
          let isAllManuals =
            selectedRowData?.filter((o1) => o1.source === 1)?.length ===
            selectedRowData?.length;
          let isAllIntegrations =
            selectedRowData?.filter((o1) => o1.source === 4)?.length ===
            selectedRowData?.length;
          let isAllBanks =
            selectedRowData?.filter((o1) => o1.source === 2)?.length ===
            selectedRowData?.length;
          let isManualFound = false;
          let isIntegrationFound = false;
          let isBankFound = false;

          selectedRowData?.forEach((o1) => {
            if (o1.source === 1) {
              isManualFound = true;
            }
            if (o1.source === 2) {
              isBankFound = true;
            }
            if (o1.source === 4) {
              isIntegrationFound = true;
            }
            if (o1?.income_expense_type === 1) {
              isInflow = true;
            }
            if (o1?.income_expense_type === 2) {
              isOutflow = true;
            }
          });
          actionInfo.current = {
            isAllManuals,
            isAllIntegrations,
            isAllBanks,
            isManualFound,
            isIntegrationFound,
            isBankFound,
            isInflow,
            isOutflow,
            ...selectedRowData?.[0],
            income_expense_type: isInflow ? 1 : 2,
            default_income_expense_type: isInflow ? 1 : 2,
          };
          if (isBankFound) {
            showInfo.current = "bulk_action_disable_actions_info_reason_booked";
            IDs = [1, 8, 9, 10, 11, 16];
          } else {
            showInfo.current = false;
            IDs = [16];
          }
        }

        if (isInflow && isOutflow) {
          IDs.push(3);
          IDs.push(4);
          IDs.push(15);
          showInfo.current =
            "bulk_action_disable_actions_info_reason_mixed_transactions";
        }

        if (tab !== "suggestionArray") {
          IDs.push(12);
        }

        if (isDevMode) {
          IDs = [];
        }
        setActionList(IDs);
      }
    },
    500,
    [selectedRowData, isDevMode],
    true
  );

  //functions
  const closeCategoryOverlay = () => {
    dispatch(setPopupStatus4(null));
  };

  const onClickCategoryTitle = (e, item) => {
    const category =
      item?.uuid === `unCategorized_category` ? null : item?.uuid;
    if (tab === "plannedArray" || tab === "staff") {
      let transaction_payload = null;
      let seq_payload = null;
      let helper_payload = null;

      if (tab === "plannedArray") {
        transaction_payload = {
          category,
        };
        seq_payload = {
          category,
        };
      }

      if (tab === "staff") {
        if (selectedUuid.current === 3) {
          transaction_payload = {
            category,
          };
          seq_payload = {
            is_department_separate: true,
          };
        }
        if (selectedUuid.current === 17) {
          seq_payload = {
            category,
          };
          helper_payload = {
            check_is_department_separate: true,
          };
        }
      }
      seqFunctionRef.current.updateSequences({
        array: selectedRowData,
        transaction_payload,
        seq_payload,
        helper_payload,
      });
    } else {
      let array = [];
      selectedRowData.forEach((element, index) => {
        array.push({
          uuid: element.uuid,
          category,
          position: 1001 + index,
        });
      });
      itemRow.current = { ...selectedRowData?.[0], category };
      updateCardsBatch(array);
    }
    closeCategoryOverlay();
  };

  const onSaveForm = (obj) => {
    let item = {};
    let transaction_payload = {};
    let seq_payload = {};
    let doNotUpdateSequences = false;
    if (form_type.current === "cost_unit") {
      item = {
        [form_type.current]: obj?.uuid,
      };
    }
    if (form_type.current === "tax") {
      item = {
        [form_type.current]: obj?.uuid,
      };
      seq_payload = item;
      doNotUpdateSequences = false;
    }
    if (form_type.current === "state" || form_type.current === "scenario") {
      item = {
        [form_type.current]: obj.title,
      };
      transaction_payload = item;
      doNotUpdateSequences = true;
    }

    if (form_type.current === "invoice_date") {
      item = {
        [form_type.current]: obj[form_type.current],
      };
    }

    if (form_type.current === "due_date") {
      item = {
        [form_type.current]: obj?.[form_type.current],
      };
      if (obj?.[form_type.current]) {
        item.invoice_date = null;
      }
    }

    if (form_type.current === "date_range") {
      if (obj?.start_date) {
        seq_payload.start_date = format(
          new Date(obj?.start_date),
          "yyyy-MM-dd"
        );
      }
      if (obj?.end_date) {
        seq_payload.end_date = format(new Date(obj?.end_date), "yyyy-MM-dd");
      }
      doNotUpdateSequences = false;
    }

    if (tab === "plannedArray" || tab === "staff") {
      let filterData = [...selectedRowData];
      if (form_type.current === "date_range" && tab === "staff") {
        let data = [];
        const isStartDate = !!obj?.start_date && !obj?.end_date;
        const isEndDate = !!obj?.end_date && !obj?.start_date;
        const isBoth = !!obj?.start_date && !!obj?.end_date;

        const groupByStaff = _.groupBy(filterData, ({ contact }) => contact);
        if (groupByStaff) {
          Object.keys(groupByStaff)?.forEach((key) => {
            const staffData = groupByStaff?.[key];
            const startDates = staffData?.map((o1) => o1?.start_date);
            const endDates = staffData?.map((o1) => o1?.end_date);
            const latestEndDate =
              endDates?.length > 0
                ? new Date(Math.max(...endDates?.map((date) => new Date(date))))
                : null;
            const earliestStartDate =
              startDates?.length > 0
                ? new Date(
                    Math.min(...startDates?.map((date) => new Date(date)))
                  )
                : null;
            if (isBoth) {
              staffData?.forEach((o1) => {
                const formattedLatestEndDate = format(
                  latestEndDate,
                  "yyyy-MM-dd"
                );
                const formattedEarliestStartDate = format(
                  earliestStartDate,
                  "yyyy-MM-dd"
                );
                if (o1.start_date === formattedEarliestStartDate) {
                  data.push({ ...o1, start_date: seq_payload.start_date });
                }
                if (o1.end_date === formattedLatestEndDate) {
                  data.push({ ...o1, end_date: seq_payload.end_date });
                }
              });
            } else {
              const latestSequences = staffData?.filter(
                (o1) =>
                  (isEndDate
                    ? formatDateToLocal(o1?.end_date) ===
                      formatDateToLocal(latestEndDate)
                    : true) &&
                  (isStartDate
                    ? formatDateToLocal(o1?.start_date) ===
                      formatDateToLocal(earliestStartDate)
                    : true)
              );

              latestSequences?.forEach((o1) => {
                if (
                  (isEndDate
                    ? new Date(obj?.end_date) >= new Date(o1?.start_date)
                    : true) ||
                  (isStartDate
                    ? new Date(obj?.start_date) <= new Date(o1?.end_date)
                    : true)
                ) {
                  data.push(o1);
                }
              });
            }
          });
        }

        filterData = [...data];
        if (isBoth) {
          seq_payload = {};
        }
      }

      seqFunctionRef.current.updateSequences({
        array: filterData,
        transaction_payload,
        seq_payload,
        doNotUpdateSequences,
      });
    } else {
      updateBatchValue(item);
    }
    closeFormOverlay();
    closeCategoryOverlay();
  };

  const closeFormOverlay = () => {
    form_type.current = null;
    dispatch(setPopupStatus4(null));
  };

  const updateBatchValue = (item) => {
    let array = [];
    let obj = {};
    selectedRowData.forEach((element, index) => {
      let categoryObj = {};
      if (item?.hasOwnProperty("state")) {
        categoryObj = getValidCategoryAfterStateUpdate({
          destination_state: item?.state,
          category: element?.category,
          gross_value: element?.gross_value,
          income_expense_type: element?.income_expense_type,
        });
      }
      obj = {
        uuid: element.uuid,
        ...item,
        ...categoryObj,
      };
      array.push(obj);
      if (index === 0) {
        itemRow.current = obj;
      }
    });
    let resetObj = null;
    if (item?.hasOwnProperty("state")) {
      resetObj = {
        refetchBalance: true,
      };
    }

    updateCardsBatch(array, resetObj);
    closeCategoryOverlay();
  };

  const handleClickSettings = (e, uuid, popupState) => {
    selectedUuid.current = uuid;
    //delete
    if (uuid === 1) {
      popupState?.close();
      dispatch(
        setPopupStatus3({
          id: "simple-popper",
          open: true,
          overlay_type: "delete",
          onConfirm: onDelete,
          payload: {
            title: t("Attention"),
            message: t(
              tab === "plannedArray" || tab === "staff"
                ? "Are you sure that you want to delete this sequences?"
                : "Are you sure that you want to delete this transaction?"
            ),
            confirmText: t("Ok"),
            hideCancel: true,
          },
        })
      );
    }

    //clone
    if (uuid === 2) {
      if (isSubscriptionValid({ showMessage: true })) {
        popupState?.close();

        if (tab === "plannedArray" || tab === "staff") {
          seqFunctionRef.current.cloneSequences({
            array: selectedRowData,
          });
        } else {
          let array = [];
          selectedRowData.forEach((item) => {
            array.push({
              uuid: item?.uuid,
              source: 1,
              transaction_system: null,
              account: null,
              reconciled: null,
              reconciled_by: null,
              last_modified_rule: null,
              state_before_split: null,
              parent: null,
              data_source:
                item?.source === 1
                  ? item?.data_source
                  : dataSetData?.internal_data_source,
            });
          });
          cloneBatch(array);
        }
      }
    }

    //categorize
    if (uuid === 3 || uuid === 17) {
      let numbers = selectedRowData.map(
        (item) => item?.gross_value || item?.average_value || item?.value
      );
      const areAllPositive = numbers.every((number) => Math.sign(number) === 1);
      const areAllNegative = numbers.every(
        (number) => Math.sign(number) === -1
      );
      let type = 3;
      if (areAllPositive) {
        type = 1;
      }
      if (areAllNegative) {
        type = 2;
      }
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: e.currentTarget,
          overlay_type: "category",
          payload: {
            type,
            onClickCategoryTitle,
          },
        })
      );
    }

    //uncategorize
    if (uuid === 5) {
      popupState?.close();

      if (tab === "plannedArray" || tab === "staff") {
        seqFunctionRef.current.updateSequences({
          array: selectedRowData,
          transaction_payload: { category: null },
          seq_payload: { category: null },
        });
      } else {
        let array = [];
        let obj = {};
        selectedRowData.forEach((item) => {
          obj = {
            uuid: item.uuid,
            category: null,
          };
          if (Constant.creditNotes?.includes(item?.state)) {
            obj.state = "Booked";
          }
          array.push(obj);
        });
        itemRow.current = selectedRowData?.[0];

        updateCardsBatch(array);
      }
    }

    //booked
    if (uuid === 13) {
      popupState?.close();

      updateBatchValue({ state: "Booked" });
    }

    //create rule
    if (uuid === 15) {
      popupState?.close();

      const titles = [...new Set(selectedRowData?.map((o1) => o1?.title))];
      let transactionsRow = {
        category: null,
        transaction_type: selectedRowData?.[0]?.income_expense_type,
      };
      if (titles?.length > 1) {
        transactionsRow.multipleTitles = titles;
      } else {
        transactionsRow.title = titles?.[0];
      }

      categoryRef?.current?.createNewRules({
        transactionsRow,
      });
    }
    if (
      uuid === 4 ||
      uuid === 7 ||
      uuid === 8 ||
      uuid === 9 ||
      uuid === 10 ||
      uuid === 14 ||
      uuid === 16
    ) {
      form_type.current = "state";
      popupState?.close();

      if (uuid === 7) {
        form_type.current = "cost_unit";
      }
      if (uuid === 8) {
        form_type.current = "scenario";
      }
      if (uuid === 9) {
        form_type.current = "due_date";
      }
      if (uuid === 10) {
        form_type.current = "invoice_date";
      }
      if (uuid === 14) {
        form_type.current = "tax";
      }
      if (uuid === 16) {
        form_type.current = "date_range";
      }
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: true,
          overlay_type: "form",
          payload: {
            type: form_type.current,
            tab,
            isRecurringRule: tab === "plannedArray" || tab === "staff",
            actionInfo: actionInfo.current,
            onSave: onSaveForm,
          },
        })
      );
    }
    if (uuid === 12) {
      popupState?.close();

      let array = [];
      selectedRowData.forEach((element, index) => {
        array.push({
          uuid: element?.uuid,
          category: element?.suggested_category,
        });
        if (index === 0) {
          itemRow.current = {
            ...element,
            category: element?.suggested_category,
          };
        }
      });
      updateCardsBatch(array);
    }
  };

  const onDelete = async () => {
    if (tab === "plannedArray" || tab === "staff") {
      seqFunctionRef.current.deleteSequences({
        array: selectedRowData,
      });
    } else {
      let uuids = selectedRowData?.map((o1) => o1.uuid);
      deleteBatch(uuids);
    }
  };

  //apis
  const deleteBatch = async (uuids) => {
    dispatch(setStageLoadingText("process_transactions_is_being_deleted"));
    await APICall("post", EndPoints.transactions + "batch_delete/", uuids).then(
      (response) => {
        dispatch(setPopupStatus3(null));
        dispatch(setStageLoadingText(null));
        if (response.status === 204) {
          if (resetKanban) resetKanban();
          enqueueSnackbar(t("Cards Deleted Successfully"));
        }
      }
    );
  };

  const cloneBatch = async (array) => {
    dispatch(setStageLoadingText("process_transactions_is_being_cloned"));
    let response = await cloneBatchTransactions(array);
    if (response) {
      enqueueSnackbar(t("Cards Cloned Successfully"));
      if (resetKanban) resetKanban();
    }
    dispatch(setStageLoadingText(null));
  };

  const updateCardsBatch = async (array, resetObj) => {
    dispatch(setStageLoadingText("process_transactions_is_being_updated"));
    const response = await updateBatchTransactions(array);
    const rulesError = response?.find((o1) =>
      o1?.category?.[0]?.includes("Cannot set category.")
    );
    if (rulesError) {
      dispatch(setStageLoadingText(null));
      rulesRef.current?.onOpen({
        payload: array,
        response,
        message: rulesError?.category?.[0],
        rule_type: rulesError?.type?.[0],
        transaction_rule: rulesError?.transaction_rule?.[0],
        title: itemRow.current?.title,
        transaction_type: itemRow.current?.income_expense_type,
        category: itemRow.current?.category,
      });
    } else {
      if (response) {
        resetKanban(resetObj);
        dispatch(setStageLoadingText(null));
      }
    }
  };

  return (
    <Tooltip
      title={disabled ? t("list_view_bulk_actions_disabled_message") : ""}
      placement="top"
    >
      <div>
        <PopupState
          variant="popper"
          id="demo-mutiple-checkbox"
          popupId="demo-popup-popover"
        >
          {(popupState) => (
            <>
              <Button
                startIcon={
                  <Icon
                    icon={<HiOutlineLightningBolt />}
                    fontSize={"1.4rem"}
                    color={
                      disabled
                        ? theme.palette.color.slate[400]
                        : theme.palette.color.white
                    }
                  ></Icon>
                }
                {...bindTrigger(popupState)}
                disabled={disabled}
                sx={{
                  minHeight: "auto",
                  height: "2rem",
                  minWidth: 0,
                  px: "0.75rem",
                  mr: "1rem",
                  color: disabled
                    ? theme.palette.color.slate[300]
                    : theme.palette.color.white,
                  backgroundColor: disabled
                    ? theme.palette.color.slate[100]
                    : theme.palette.primary.dark,
                  borderRadius: 6,
                  fontWeight: 500,
                  textTransform: "initial",
                  fontSize: "0.9rem",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.darkHover,
                  },
                }}
              >
                {t("Run bulk action")}
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      borderRadius: 1.5,
                      boxShadow: Constant.OverlayBoxShadow,
                    },
                  },
                }}
                sx={{
                  ml: "0.5rem",
                }}
              >
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "fit-content",
                    minHeight: "3.125rem",
                    width: "fit-content",
                    minWidth: "18.75rem",
                    position: "relative",
                  }}
                >
                  {showInfo.current ? (
                    <Typography
                      variant="caption"
                      fontWeight={"fontWeightMediumBold"}
                      sx={{
                        color: theme.palette.color.slate[500],
                        mx: "1.25rem",
                        mb: `0.5rem`,
                      }}
                    >
                      {t(showInfo.current)}
                    </Typography>
                  ) : null}
                  {actionListOption?.map((item) => {
                    const disabled = actionList?.includes(item?.uuid);
                    if (tab === "staff" && disabled) {
                      return null;
                    }
                    return (
                      <ListItem
                        key={`key-${item?.uuid}`}
                        disableGutters
                        disablePadding
                        onClick={
                          disabled
                            ? undefined
                            : (e) =>
                                handleClickSettings(e, item?.uuid, popupState)
                        }
                      >
                        <ListItemButton
                          disabled={disabled}
                          sx={{
                            "&:hover": {
                              backgroundColor: theme.palette.primary[50],
                              color: theme.palette.primary.main,
                              "& span": {
                                fontWeight: 500,
                                color: theme.palette.primary.main,
                              },
                              "& svg": {
                                color: theme.palette.primary.main,
                              },
                            },
                          }}
                        >
                          <ListItemIcon>{item?.icon}</ListItemIcon>
                          <ListItemText primary={t(item?.title)} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Popover>
            </>
          )}
        </PopupState>
      </div>
    </Tooltip>
  );
};

export default TransactionViewSettingPopOver;
