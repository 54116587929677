import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { PiPlugs, PiPlugsConnected } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { GrConnect, GrUpdate } from "react-icons/gr";
import { Tooltip, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FiAlertTriangle } from "react-icons/fi";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { enqueueSnackbar } from "notistack";
import { IoMdAlert } from "react-icons/io";

import {
  getFinApiDataSourceConnection,
  getTransactionByParams,
  getUnifiedRedirectUrl,
  getIntegrationCard,
  getFinApiQueryKey,
  formatDateToLocal,
} from "../../../Helper/data";
import {
  setIsAccountSelectOverlayOpen,
  setIsDsUuidLoading,
  setDataSource,
} from "../../../store/slices/global";
import { setStageLoadingText } from "../../../store/slices/appmain";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import initialData, { formatAmount } from "../../../Helper/data";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { setAddFrom } from "../../../store/slices/settings";
import useStatusHook from "../../../hooks/useStatusHook";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";
import { Constant } from "../../../Helper";
import APICall from "../../../APICall";

const accountType = initialData?.account_Type
  ?.filter((o1) => !["Loan", "CREDIT_CARD"]?.includes(o1.value))
  ?.map((o1) => o1.value);
const StatusView = ({
  uuid,
  type = "button",
  onClickErrorLogs,
  refetch,
  showBalance,
  sx,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();

  const globalContext = useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;
  const color = useRef("red");
  const title = useRef();
  const tooltip = useRef("");
  const allowClick = useRef(false);
  const cursor = useRef("auto");
  const icon = useRef(undefined);
  const notification = useRef(undefined);
  const severity = useRef("info");

  //redux
  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );
  const isDsUuidLoading = useSelector(
    (state) => state.globalSlice.isDsUuidLoading
  );

  const dataSourceById = useSelector(
    (state) => state.globalSlice.dataSourceById
  );
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);
  const stageText = useSelector((state) => state.appSlice.stageText);

  const account = accountByDS?.[uuid]?.[0];
  const ds = dataSourceById?.[uuid]?.[0];
  const card = getIntegrationCard({ ds });
  //state
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState(false);

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
        setRequesting(false);
      }
    });
  };

  const updateApiKey = async (integration_id, obj) => {
    await APICall(
      "patch",
      EndPoints.integrations + `${integration_id}/external/config/`,
      obj
    ).then(async (response) => {
      refetchIntegrationsConnection({
        integration_id: integration_id,
      });
    });
  };

  const handleGoogleAuthResponse = async (response, payload) => {
    if (response.error) {
      refetchIntegrationsConnection({
        integration_id: payload.integration_id,
      });
    } else {
      if (response.code) {
        const obj = {
          sheet_id: payload?.config?.sheet_id,
          authorization_code: response.code,
        };
        await updateApiKey(payload?.integration_id, obj);
      } else {
        refetchIntegrationsConnection({
          integration_id: payload.integration_id,
        });
      }
    }
  };

  const handleGoogleErrorResponse = (error) => {
    setRequesting(false);
  };

  const getApiKey = async (integration_id) => {
    await APICall(
      "get",
      EndPoints.integrations + `${integration_id}/external/config/`
    )
      .then(async (response) => {
        if (response.status === 200 && response.data) {
          if (
            data_source_by_uuid_external_connections?.data === "token_error"
          ) {
            if (!response.data?.client_id) {
              setRequesting(false);
              enqueueSnackbar(t("gsheet_client_id_not_found"), {
                variant: "error",
                autoHideDuration: 4000,
                preventDuplicate: true,
              });
              return true;
            }
            const googleTokenClient =
              await window?.google?.accounts?.oauth2?.initCodeClient({
                client_id: response.data?.client_id,
                state: integration_id,
                callback: (res) =>
                  handleGoogleAuthResponse(res, {
                    integration_id,
                    config: response.data,
                  }),
                error_callback: handleGoogleErrorResponse,
                scope: Constant.SCOPES,
                ux_mode: "popup",
              });

            googleTokenClient?.requestCode();

            return true;
          } else if (response.data?.authorization_url) {
            dispatch(
              setAddFrom({
                integration_id: integration_id,
                targetUrl: window.location.pathname,
              })
            );
            dispatch(setIsDsUuidLoading(integration_id));
            dispatch(setPopupStatus3(null));
            setTimeout(() => {
              let win = window.open(response.data?.authorization_url, "_self");
              win.focus();
            }, 100);
          }
          if (
            [
              "Pipedrive",
              "HubSpot",
              "Xero",
              "QuickBooks",
              "Zoho CRM",
              "Zoho Books",
              "Zoho Invoice",
              "Shopify",
            ].includes(card?.name)
          ) {
            const integrationType = response.data?.integration_type;
            const integrationDetails = {
              API_URL: response.data?.api_root,
              workspaceId: response.data?.workspace_id,
              type: integrationType,
              state: integration_id,
              scopes: response.data?.scopes,
              environment: response.data?.environment,
              success_redirect: window.location.href,
              failure_redirect: `${window.location.href}?isUnifiedError=true`,
            };

            const redirectUrl = getUnifiedRedirectUrl(integrationDetails);
            dispatch(setPopupStatus3(null));
            dispatch(
              setAddFrom({
                integration_id: integration_id,
                targetUrl: window.location.pathname,
                type: integrationType,
              })
            );

            setTimeout(() => {
              let win = window.open(redirectUrl, "_self");
              win.focus();
            }, 100);
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const updateDataSourceByID = async (ds, obj, dsType) => {
    await APICall("patch", EndPoints.integrations + `${ds?.uuid}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          if (dsType !== "finApi") {
            getApiKey(ds?.uuid);
          }
        }
      }
    );
  };

  const reconnectFinapiDS = async (ds) => {
    await APICall(
      "post",
      EndPoints.integrations + `${ds?.uuid}/finapi/reconnect/`,
      {}
    ).then((response) => {
      if (response.status === 200 && response.data) {
        queryClient.removeQueries({
          queryKey: FinApiQueryKey,
        });
        getDataSource();
      }
    });
  };

  const getDataSourceError = async (id) => {
    let data = null;

    await APICall("get", EndPoints.integrations + `${id}/logs/`).then(
      (response) => {
        if (response.status === 200 && response.data) {
          data = response.data;
        }
      }
    );
    return data;
  };

  const FinApiQueryKey = getFinApiQueryKey({ data_source: ds?.uuid });

  const IntegrationsQueryKey = useMemo(
    () => [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_external_connections",
      },
    ],
    [ds?.uuid]
  );

  const data_source_by_uuid_finapi_connections = useStatusHook(null, {
    queryKey: FinApiQueryKey,
  });
  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: IntegrationsQueryKey,
  });

  const data_source_error_by_uuid = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_fetch_errors",
      },
    ],
    queryFn: ({ signal }) => {
      const result = getDataSourceError(ds?.uuid);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 5,
    staleTime: 1000 * 60 * 60 * 24, //24hour
    enabled:
      !!ds?.uuid &&
      !!ds?.is_connected &&
      !!ds?.last_sync_date &&
      global.isFirstFetchUuid?.length === 0,
  });

  const Integration_Transactions = useQuery({
    queryKey: [
      "integrations",
      {
        page: 1,
        page_size: 1,
        data_source: [ds?.uuid],
        apiType: "Integration_Transactions",
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        page: 1,
        page_size: 1,
        data_source: [ds?.uuid],
      };

      const result = getTransactionByParams(params);
      if (result) {
        return result;
      }
    },
    cacheTime: 5000,
    backgroundFetch: true,
    enabled:
      !!ds?.uuid &&
      !!ds?.is_connected &&
      !!ds?.last_sync_date &&
      !isDsUuidLoading &&
      ds?.type === 19 &&
      isFirstFetchDs === 0 &&
      !data_source_by_uuid_external_connections?.isFetching &&
      data_source_by_uuid_external_connections?.data === "UPDATED",
  });

  const isLoading =
    data_source_by_uuid_external_connections?.isFetching ||
    data_source_by_uuid_finapi_connections?.isFetching ||
    isDsUuidLoading === uuid;

  //functions
  const refetchIntegrationsConnection = (payload) => {
    const options = {
      predicate: (query) =>
        query.queryKey[0] === "integrations" &&
        query.queryKey[1]?.data_source === payload?.integration_id &&
        query.queryKey[1]?.apiType === "data_source_external_connections",
    };
    queryClient.resetQueries(options);
    setRequesting(false);
  };

  const onLexOfficeConfirm = async ({ payload }) => {
    setRequesting(true);
    // dispatch(setIsAllHeaderApiFetched(false));
    if (ds?.state === 3 || ds?.state === 2) {
      await updateDataSourceByID(ds, { state: 1 }, "integration");
    } else {
      getApiKey(payload?.ds?.uuid);
    }
  };

  const onLexOfficeClose = () => {
    setRequesting(false);
  };

  const onClickIconView = async () => {
    if (!requesting && !isLoading) {
      if (error && onClickErrorLogs) {
        onClickErrorLogs(ds, error);
        return null;
      }
      if (ds?.type === 10) {
        return null;
      }
      if (ds?.type === 12) {
        setRequesting(true);

        if (account) {
          const connections = await queryClient.fetchQuery({
            queryKey: FinApiQueryKey,
            queryFn: async ({ signal }) => {
              try {
                const result = await getFinApiDataSourceConnection(ds?.uuid);
                return result ?? "";
              } catch (error) {
                return "";
              }
            },
            backgroundFetch: true,
          });
          if (
            connections === "NOT_YET_OPENED" ||
            connections === "COMPLETED_WITH_ERROR" ||
            connections === "ABORTED" ||
            connections === "EXPIRED" ||
            connections === "ERROR"
          ) {
            setRequesting(false);
            dispatch(setStageLoadingText(null));
            dispatch(
              setIsAccountSelectOverlayOpen({
                open: true,
                isBankSelection: true,
                payload: {
                  integration_id: ds?.uuid,
                },
              })
            );
          } else if (connections === "DISCONNECTED") {
            if (ds?.state !== 1) {
              await updateDataSourceByID(ds, { state: 1 }, "finApi");
            }
            await reconnectFinapiDS(ds);
          } else if (
            connections === "USER_ACTION_REQUIRED" ||
            connections === "USER_ACTION_REQUIRED_WITH_ACTION"
          ) {
            dsRef.current?.updateConsents({
              DS: ds,
              _fetchType: null,
            });
          } else if (!ds?.last_sync_date) {
            dsRef.current?.fetchAllTransaction(ds);
            setRequesting(false);
          } else {
            setRequesting(false);
          }
        } else {
          dispatch(
            setIsAccountSelectOverlayOpen({
              open: true,
              isBankSelection: true,
              payload: {
                integration_id: ds?.uuid,
              },
            })
          );
          setRequesting(false);
        }
      }
      if (ds?.type === 19) {
        if (
          [
            "Lexoffice",
            "Pipedrive",
            "HubSpot",
            "Xero",
            "QuickBooks",
            "Zoho CRM",
            "Zoho Books",
            "Zoho Invoice",
            "Shopify",
          ].includes(card?.name)
        ) {
          let title = "info_unified_auth_title";
          let message = "info_unified_auth_message";
          let confirmText = "info_unified_auth_confirm_text";
          let linkText = "info_unified_auth_link_text";

          if (card?.name === "Lexoffice") {
            title = "info_lexoffice_title";
            message = "info_lexoffice_message";
            confirmText = "info_lexoffice_confirm_text";
            linkText = "";
          }
          dispatch(
            setPopupStatus3({
              id: "simple-popper",
              overlay_type: "info",
              open: true,
              onConfirm: onLexOfficeConfirm,
              onClose: onLexOfficeClose,
              payload: {
                title: <Translate i18nkey={title} />,
                message: <Translate i18nkey={message} />,
                confirmText: <Translate i18nkey={confirmText} />,
                linkText: linkText ? <Translate i18nkey={linkText} /> : "",
                link: "https://unified.to/privacy",
                ds,
              },
            })
          );
          return;
        } else {
          setRequesting(true);
          await getApiKey(ds?.uuid);
        }
      }
    }
  };

  const checkConnections = () => {
    const options = {
      queryKey: ds?.type === 12 ? FinApiQueryKey : IntegrationsQueryKey,
    };
    queryClient.resetQueries(options);
  };

  const getBalance = (balances) => {
    const total = balances?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Number(item?.amount || 0) + Number(item?.ignored_value || 0)
        ),
      0
    );
    return parseFloat(total).toFixed(2);
  };

  if (ds && ds?.type === 1 && ds.internal_dataset) {
    title.current = "connection_state_connected";
    tooltip.current = t("connection_state_connected_tooltip");
    color.current = "green";
    cursor.current = "auto";
    severity.current = "success";
    icon.current = (
      <DoneIcon
        sx={{
          color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
          fontSize: "1.1rem",
        }}
      />
    );
  }

  if (ds && ds?.type === 1 && !ds.internal_dataset) {
    if (account) {
      if (ds?.state === 1) {
        title.current = "connection_state_connected";
        tooltip.current = t("connection_state_connected_tooltip");
        color.current = "green";
        cursor.current = "auto";
        severity.current = "success";
        icon.current = (
          <DoneIcon
            sx={{
              color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              fontSize: "1.1rem",
            }}
          />
        );
      }
    } else {
      title.current = "connection_state_connect";
      color.current = "blue";
      severity.current = "info";
      cursor.current = "pointer";
      allowClick.current = true;
      icon.current = (
        <Icon
          icon={<GrConnect />}
          fontSize={"0.9rem"}
          color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          style={{
            paddingLeft: "0.25rem",
          }}
        ></Icon>
      );
    }
  }

  if (ds && ds?.type === 12) {
    notification.current = undefined;
    if (account && data_source_by_uuid_finapi_connections?.data) {
      if (
        data_source_by_uuid_finapi_connections?.data === "COMPLETED" ||
        data_source_by_uuid_finapi_connections?.data === "UPDATED"
      ) {
        tooltip.current = t("connection_state_connected_tooltip");
        title.current = "connection_state_connected";
        color.current = "green";
        severity.current = "success";
        cursor.current = "auto";
        icon.current = (
          <DoneIcon
            sx={{
              color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              fontSize: "1rem",
            }}
          />
        );
        if (data_source_by_uuid_finapi_connections?.data === "UPDATED") {
          notification.current = true;
          tooltip.current = t(
            "connection_state_connected_with_action_required_tooltip"
          );
        }
      } else if (
        !ds?.is_connected &&
        data_source_by_uuid_finapi_connections?.data === "DISCONNECTED"
      ) {
        title.current = "connection_state_reconnect";
        color.current = "red";
        cursor.current = "pointer";
        severity.current = "error";
        allowClick.current = true;
        tooltip.current = t("connection_state_reconnect");
        icon.current = (
          <Icon
            icon={<GrConnect />}
            fontSize={"0.9rem"}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
          ></Icon>
        );
      } else if (
        !data_source_by_uuid_finapi_connections?.data ||
        data_source_by_uuid_finapi_connections?.data === "UNAUTHORIZED_ACCESS"
      ) {
        allowClick.current = false;
        title.current = "connection_state_unauthorized_access";
        tooltip.current = t("connection_state_unauthorized_access_tooltip");
        color.current = "red";
        severity.current = "error";
        icon.current = null;
        cursor.current = "auto";
      } else if (
        data_source_by_uuid_finapi_connections?.data === "IS_DEPRECATED"
      ) {
        allowClick.current = false;
        title.current = "connection_state_deprecated";
        tooltip.current = t("connection_state_deprecated_tooltip");
        color.current = "red";
        severity.current = "error";
        icon.current = null;
        cursor.current = "auto";
      } else if (
        data_source_by_uuid_finapi_connections?.data ===
        "USER_ACTION_REQUIRED_WITH_ACTION"
      ) {
        notification.current = true;
        tooltip.current = t(
          "connection_state_connected_with_action_required_tooltip"
        );
        title.current = "connection_state_update";
        color.current = "blue";
        cursor.current = "pointer";
        severity.current = "info";
        icon.current = (
          <Icon
            icon={<GrUpdate />}
            fontSize={"0.8rem"}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
            path={{
              strokeWidth: 3,
            }}
          ></Icon>
        );
        allowClick.current = true;
      } else {
        tooltip.current = t("connection_state_update_tooltip");
        title.current = "connection_state_update";
        color.current = "blue";
        cursor.current = "pointer";
        severity.current = "info";
        icon.current = (
          <Icon
            icon={<GrUpdate />}
            fontSize={"0.8rem"}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
            path={{
              strokeWidth: 3,
            }}
          ></Icon>
        );
        allowClick.current = true;
      }
    } else if (
      account &&
      !ds.last_sync_date &&
      isFirstFetchDs !== 0 &&
      global.isFirstFetchUuid?.includes(ds.uuid)
    ) {
      icon.current = (
        <Icon
          icon={<GrConnect />}
          fontSize={"0.9rem"}
          color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          style={{
            paddingLeft: "0.25rem",
          }}
        ></Icon>
      );
      tooltip.current = t("Fetching Data");
      color.current = "green";
      severity.current = "success";
      title.current = "Fetching Data";
    } else if (!account) {
      title.current = "connection_state_connect";
      color.current = "red";
      cursor.current = "pointer";
      severity.current = "error";
      allowClick.current = true;
      tooltip.current = t("connection_state_connect");
      icon.current = (
        <Icon
          icon={<PiPlugsConnected />}
          fontSize={"1.1rem"}
          color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          strokeWidth={8}
          style={{
            paddingLeft: "0.25rem",
          }}
        ></Icon>
      );
    }
  }

  if (ds && ds?.type === 19) {
    if (data_source_by_uuid_external_connections?.data === "UPDATED") {
      title.current = "connection_state_connected";
      tooltip.current = t("connection_state_connected_tooltip");
      color.current = "green";
      severity.current = "success";
      allowClick.current = false;
      cursor.current = "auto";
      icon.current = (
        <DoneIcon
          sx={{
            fontSize: "1.1rem",
            color: color.current
              ? `${theme.palette?.color?.[color.current]?.[500]} !important`
              : "inherit",
          }}
        />
      );
    } else if (
      data_source_by_uuid_external_connections?.data === "token_error"
    ) {
      title.current = "connection_state_reauthorize";
      tooltip.current = t("connection_state_reauthorize_tooltip");
      cursor.current = "cursor";
      color.current = "red";
      allowClick.current = true;
      severity.current = "error";
      icon.current = (
        <Icon
          icon={<GrConnect />}
          fontSize={"0.9rem"}
          color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          style={{
            paddingLeft: "0.25rem",
          }}
        ></Icon>
      );
    } else if (
      !data_source_by_uuid_external_connections?.data ||
      data_source_by_uuid_external_connections?.data === "UNAUTHORIZED_ACCESS"
    ) {
      allowClick.current = false;
      title.current = "connection_state_unauthorized_access";
      tooltip.current = t("connection_state_unauthorized_access_tooltip");
      cursor.current = "auto";
      color.current = "red";
      severity.current = "error";
      icon.current = null;
    } else {
      if (
        [
          "Lexoffice",
          "Pipedrive",
          "HubSpot",
          "Xero",
          "QuickBooks",
          "Zoho CRM",
          "Zoho Books",
          "Zoho Invoice",
          "Shopify",
        ].includes(card?.name)
      ) {
        tooltip.current = t("integrations_connection_state_update_tooltip");
        title.current = "connection_state_update";
        color.current = "blue";
        cursor.current = "pointer";
        severity.current = "info";
        icon.current = (
          <Icon
            icon={<GrUpdate />}
            fontSize={"0.8rem"}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
            path={{
              strokeWidth: 3,
            }}
          ></Icon>
        );
        allowClick.current = true;
      } else {
        title.current = "connection_state_disconnected";
        tooltip.current = t("connection_state_disconnected_tooltip");
        color.current = "red";
        severity.current = "error";
        icon.current = (
          <Icon
            icon={<PiPlugs />}
            fontSize={"1.1rem"}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
          ></Icon>
        );

        if (ds?.state === 3) {
          title.current = "connection_state_disconnected";
          tooltip.current = t("connection_state_disconnected_tooltip");
          color.current = "red";
          severity.current = "error";
          allowClick.current = false;
          icon.current = (
            <Icon
              icon={<PiPlugs />}
              fontSize={"1.1rem"}
              color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
              stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
              style={{
                paddingLeft: "0.25rem",
              }}
            ></Icon>
          );
        }
      }
    }
  }

  if (ds.state === 3) {
    title.current = "connection_state_reconnect";
    color.current = "red";
    cursor.current = "pointer";
    severity.current = "error";
    allowClick.current = true;
    tooltip.current = t("connection_state_reconnect");
    icon.current = (
      <Icon
        icon={<GrConnect />}
        fontSize={"0.9rem"}
        color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }

  if (ds?.state === 2 || ds?.type === 10) {
    title.current = "connection_state_disconnected";
    tooltip.current = t("connection_state_disconnected_tooltip");
    color.current = "red";
    severity.current = "error";
    allowClick.current = false;
    cursor.current = "not-allowed";
    icon.current = (
      <Icon
        icon={<PiPlugs />}
        fontSize={"1.1rem"}
        color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }

  if (error) {
    title.current = "Errors";
    tooltip.current = t("Error");
    allowClick.current = true;
    color.current = "red";
    severity.current = "error";
    cursor.current = "pointer";
    icon.current = (
      <Icon
        icon={<IoMdAlert />}
        fontSize={"1.1rem"}
        color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }
  if (isFirstFetchDs !== 0 && global.isFirstFetchUuid?.includes(ds?.uuid)) {
    tooltip.current = t("Fetching Data");
    color.current = "green";
    severity.current = "success";
    title.current = "Fetching Data";
    icon.current = (
      <Icon
        icon={<GrConnect />}
        fontSize={"0.9rem"}
        color={
          color.current
            ? `${theme.palette?.color?.[color.current]?.[500]} !important`
            : "inherit"
        }
        stroke={
          color.current
            ? `${theme.palette?.color?.[color.current]?.[500]} !important`
            : "inherit"
        }
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }

  if (requesting) {
    title.current = "Request sent";
  }

  useEffect(() => {
    if (requesting && ds && ds?.type === 12) {
      if (
        data_source_by_uuid_finapi_connections?.data === "COMPLETED" ||
        data_source_by_uuid_finapi_connections?.data === "UPDATED" ||
        !stageText
      ) {
        setRequesting(false);
      }
    }
  }, [data_source_by_uuid_finapi_connections?.data, ds, requesting, stageText]);

  useEffect(() => {
    if (requesting && ds && ds?.type === 19) {
      if (data_source_by_uuid_external_connections?.data === "UPDATED") {
        setRequesting(false);
      }
    }
  }, [data_source_by_uuid_external_connections?.data, ds, requesting]);

  useDebounce(
    () => {
      if (refetch && ds?.uuid && !global.isFirstFetchUuid?.includes(ds?.uuid)) {
        checkConnections();
      }
    },
    200,
    [refetch],
    true
  );

  useEffect(() => {
    if (data_source_error_by_uuid?.data?.count > 0) {
      const [firstItem] = data_source_error_by_uuid.data?.results;
      if (
        firstItem?.total_error_entries > 0 &&
        ds?.state !== 2 &&
        !global.isFirstFetchUuid?.includes(ds?.uuid)
      ) {
        setError(firstItem);
      } else {
        setError(false);
      }
    }
  }, [data_source_error_by_uuid?.data]);

  useEffect(() => {
    if (
      ds &&
      ds?.type === 19 &&
      ["Google Sheets API", "Google Sheets API 2"].includes(card?.name)
    ) {
      if (
        !document.querySelector(
          'script[src="https://accounts.google.com/gsi/client"]'
        )
      ) {
        const gisLoadedScript = document.createElement("script");
        gisLoadedScript.src = "https://accounts.google.com/gsi/client";
        gisLoadedScript.async = true;
        gisLoadedScript.defer = true;
        document.body.appendChild(gisLoadedScript);
      }
    }
  }, [card?.name, data_source_error_by_uuid.data, ds]);

  const balance = useMemo(() => {
    let num = 0;
    if (showBalance) {
      if (
        !!ds?.uuid &&
        !!ds?.is_connected &&
        !!ds?.last_sync_date &&
        data_source_by_uuid_external_connections?.data === "UPDATED"
      ) {
        num = Integration_Transactions?.data?.count || 0;
      }
      if (ds?.type === 1 && account && account?.balances) {
        num = getBalance(account?.balances) || 0;
      }
      if (ds?.type === 12 && account && account?.balances) {
        account?.balances?.forEach((element) => {
          if (
            accountType?.includes(element?.name) ||
            element?.name === "booked"
          ) {
            num =
              Number(element?.amount || 0) +
              Number(element?.ignored_value || 0);
          }
        });
      }
    }
    return num;
  }, [
    Integration_Transactions?.data?.count,
    account,
    data_source_by_uuid_external_connections?.data,
    ds?.is_connected,
    ds?.last_sync_date,
    ds?.type,
    ds?.uuid,
    showBalance,
  ]);

  const balance_tooltip = `${
    ds?.type !== 19 &&
    ds?.last_sync_date &&
    showBalance &&
    color.current !== "red"
      ? `${t("Current Balance, Last update on")} ${formatDateToLocal(
          new Date(ds?.last_sync_date),
          "yyyy-MM-dd"
        )}`
      : showBalance && ds?.type === 19 && balance && color.current !== "red"
        ? t(`integrations_transaction_count_tooltip`, { count: balance })
        : ""
  }`;
  // if (ds.type === 19 && ds.note === "GetMyInvoices") {
  //   console.log(
  //     "🚀 ds:",
  //     ds,
  //     data_source_by_uuid_external_connections,
  //     isFirstFetchDs,
  //     global?.isFirstFetchUuid
  //   );
  // }

  // if (ds.type === 12) {
  //   console.log(
  //     "🚀finapi",
  //     ds?.title,
  //     data_source_by_uuid_finapi_connections?.data,
  //     { ds, account }
  //     // isFirstFetchDs !== 0 && global?.isFirstFetchUuid?.includes(ds?.uuid),
  //     // global?.isFirstFetchUuid
  //   );
  // }

  return (
    <Tooltip
      placement="top"
      title={`${t(title.current)}: ${tooltip.current}`}
      followCursor
    >
      <span>
        {type === "dot" ? null : (
          <Button
            variant="outlined"
            size="small"
            onClick={allowClick.current ? onClickIconView : undefined}
            loading={
              (isFirstFetchDs !== 0 &&
                global?.isFirstFetchUuid?.includes(ds?.uuid)) ||
              isLoading ||
              requesting
            }
            // loading
            loadingPosition={
              (isFirstFetchDs !== 0 &&
                global?.isFirstFetchUuid?.includes(ds?.uuid)) ||
              requesting
                ? "start"
                : "center"
            }
            startIcon={(!isLoading || requesting) && icon.current}
            disableElevation
            disableRipple={!allowClick.current}
            color="primary"
            sx={{
              height: "1.8rem",
              fontSize: "0.7rem",
              lineHeight: "inherit",
              minWidth: type === "alert" ? "8rem" : "none",
              width: "fit-content",
              justifyContent: "space-between",
              borderRadius: theme.borderRadius.main,
              color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              backgroundColor: theme.palette?.color?.[color.current]?.[50],
              cursor: cursor.current,
              fontWeight: 600,
              border: "none",
              borderColor: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              textTransform: "initial",
              "& .MuiCircularProgress-root": {
                color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              },

              "&: hover": {
                backgroundColor: `${theme.palette?.color?.[color.current]?.[allowClick.current ? 100 : 50]} !important`,
                border: "none",
              },
              ...sx,
            }}
          >
            {!isLoading || requesting ? (
              showBalance && color.current !== "red" ? (
                <Tooltip placement="top" title={balance_tooltip} followCursor>
                  {formatAmount({
                    amount: balance,
                    count: ds?.type === 19 ? 0 : 2,
                    hidePrefix: ds?.type === 19,
                  })}
                </Tooltip>
              ) : (
                t(title.current)
              )
            ) : (
              ""
            )}
            {isLoading || requesting || !notification.current ? (
              ""
            ) : (
              <FiAlertTriangle
                style={{
                  marginLeft: "0.25rem",
                  color: theme.palette?.color?.["amber"]?.[500],
                }}
              />
            )}
          </Button>
        )}
        {type === "dot" ? (
          <div
            style={{
              position: "absolute",
              left: "2%",
              top: "42%",
              width: "8px",
              height: "8px",
              borderRadius: "4px",
              backgroundColor: theme.palette?.color?.[color.current]?.[500],
              marginRight: "0.5rem",
              ...sx,
            }}
          ></div>
        ) : null}
      </span>
    </Tooltip>
  );
};
export default StatusView;
