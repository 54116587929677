import React, { useEffect, useState } from "react";
import { Popover, useTheme } from "@mui/material";

import FilterOverlay from "./FilterOverlay";

const AddFilterPopOver = ({
  anchorEl,
  onClose,
  selectedIds,
  color = "slate",
  options,
}) => {
  const theme = useTheme();

  const [_selectedIds, setSelectedIds] = useState(selectedIds);
  const [searchText, setSearchText] = useState("");

  const open = Boolean(anchorEl);
  const onChangeValue = (selectedIds, isNewAdded) => {
    setSelectedIds(selectedIds);
    if (isNewAdded) {
      onClose(selectedIds);
    }
  };

  const onClosePopOver = () => {
    onClose(_selectedIds);
  };

  useEffect(() => {
    if (open) {
      setSelectedIds(selectedIds);
    }
  }, [selectedIds, open]);

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClosePopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        mt: "0.5rem",
        "& .MuiPaper-root": {
          borderRadius: theme.borderRadius.main,
          boxShadow: theme.boxShadow,
        },
      }}
    >
      <FilterOverlay
        selectedIds={_selectedIds}
        options={options}
        anchorEl={anchorEl}
        color={color}
        translate
        onChangeValue={onChangeValue}
        searchText={searchText}
        setSearchText={setSearchText}
        searchType="client"
      />
    </Popover>
  );
};

export default AddFilterPopOver;
